import React from 'react';

export default function Pause() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
        >
            <path
                d="M30.563.563a30,30,0,1,0,30,30A29.995,29.995,0,0,0,30.563.563ZM28.627,40.24a1.941,1.941,0,0,1-1.935,1.935H20.885A1.941,1.941,0,0,1,18.95,40.24V20.885a1.941,1.941,0,0,1,1.935-1.935h5.806a1.941,1.941,0,0,1,1.935,1.935Zm13.548,0a1.941,1.941,0,0,1-1.935,1.935H34.433A1.941,1.941,0,0,1,32.5,40.24V20.885a1.941,1.941,0,0,1,1.935-1.935H40.24a1.941,1.941,0,0,1,1.935,1.935Z"
                transform="translate(-0.563 -0.563)"
            />
        </svg>
    );
}
