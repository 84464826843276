import React from 'react';

export default function IncText() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.006"
            height="13.417"
            viewBox="0 0 16.006 13.417"
        >
            <g transform="translate(-90.629 -212.115)">
                <g transform="translate(96.422 212.115)">
                    <path
                        d="M4.667,0V-11.364H.422v-1.521H10.635v1.521H6.372V0Z"
                        transform="translate(-0.422 12.885)"
                        fill="#0c0b26"
                    />
                </g>
                <path
                    d="M5.363,3.387v.565a.212.212,0,0,1-.212.212H4.164v.988a.212.212,0,0,1-.212.212H3.387a.212.212,0,0,1-.212-.212V4.164H2.188a.212.212,0,0,1-.212-.212V3.387a.212.212,0,0,1,.212-.212h.988V2.188a.212.212,0,0,1,.212-.212h.565a.212.212,0,0,1,.212.212v.988h.988A.212.212,0,0,1,5.363,3.387ZM8.909,8.41l-.5.5a.422.422,0,0,1-.6,0L6.051,7.15a.423.423,0,0,1-.123-.3V6.563a3.668,3.668,0,1,1,.635-.635h.288a.423.423,0,0,1,.3.123L8.909,7.81A.427.427,0,0,1,8.909,8.41ZM6.069,3.67a2.4,2.4,0,1,0-2.4,2.4A2.4,2.4,0,0,0,6.069,3.67Z"
                    transform="translate(99.663 216.5) rotate(90)"
                />
            </g>
        </svg>
    );
}
