import React from 'react';
import './ProgressBar.css';

export default function ProgressBar(props) {
    return (
        <div className="progress">
            <div
                className="progress-bar"
                role="progressbar"
                style={{
                    width: props.progress + '%',
                    transition: 'all 0.3s ease',
                }}
                aria-valuenow={props.progress}
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    );
}
