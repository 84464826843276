import React, { useState } from 'react';
import RightEar from '../../components/icons/RightEar';
import LeftEar from '../../components/icons/LeftEar';
import CochlearImplant from '../../components/icons/CochlearImplant';
import Ear from '../../components/icons/Ear';
import Earphones from '../../components/icons/Earphones';
import HearAid from '../../components/icons/HearAid';

export default function OptList(props) {
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const data = props.data;
    const hasSide = data.side ? true : false;
    function insertAnswer(key, value) {
        setSelectedAnswer(value);
        props.insertAnswer(key, value);
    }
    const options = data.options
        ? data.options.map((opt, i) => {
              let classes = 'btn-selectable';
              classes += selectedAnswer === opt.value ? ' active' : '';
              classes += opt.icon ? ' has-icon' : '';

              return (
                  <button
                      className={classes}
                      key={i + 1}
                      data-id={i + 1}
                      onClick={() => {
                          insertAnswer(data.label, opt.value);
                      }}
                  >
                      {(() => {
                          switch (opt.icon) {
                              case 'ear-aid':
                                  return (
                                      <span className="icon">
                                          <HearAid
                                              selected={
                                                  selectedAnswer === opt.value
                                                      ? true
                                                      : false
                                              }
                                          />
                                      </span>
                                  );
                              case 'cochlear-implant':
                                  return (
                                      <span className="icon">
                                          <CochlearImplant
                                              selected={
                                                  selectedAnswer === opt.value
                                                      ? true
                                                      : false
                                              }
                                          />
                                      </span>
                                  );
                              case 'ear':
                                  return (
                                      <span className="icon">
                                          <Ear
                                              selected={
                                                  selectedAnswer === opt.value
                                                      ? true
                                                      : false
                                              }
                                          />
                                      </span>
                                  );
                              case 'earphones':
                                  return (
                                      <span className="icon">
                                          <Earphones
                                              selected={
                                                  selectedAnswer === opt.value
                                                      ? true
                                                      : false
                                              }
                                          />
                                      </span>
                                  );
                              default:
                                  return;
                          }
                      })()}

                      {
                          <span
                              dangerouslySetInnerHTML={{ __html: opt.text }}
                          ></span>
                      }
                  </button>
              );
          })
        : '';
    return (
        <div className="opt-list">
            {hasSide && data.side === 'right' && (
                <h2 className="ear-title ear-right">
                    {/* <LeftEar /> */}
                    <span className="text">אוזן ימין</span>
                </h2>
            )}
            {hasSide && data.side === 'left' && (
                <h2 className="ear-title ear-left">
                    <span className="text">אוזן שמאל</span>
                    {/* <RightEar /> */}
                </h2>
            )}
            <div className="options">{options}</div>
        </div>
    );
}
