import React from 'react';
import Logo from '../Logo';
// import coffee from '../../assets/images/coffee.svg';
import './Header.css';

export default function Header(props) {
    const showExit = () => {
        props.showExit();
    };
    return (
        <header className="header">
            <div>
                {props.canPause === true && (
                    <button
                        className="btn btn-break"
                        aria-label="יציאה להפסקה"
                        onClick={showExit}
                    >
                        <span>צא והמשך מאוחר יותר</span>
                    </button>
                )}
            </div>
            <Logo mode={props.mode} />
        </header>
    );
}
