import React from 'react';

export default function HearAid(props) {
    let primaryColor = props.selected === true ? '#4357e7' : '#516371';
    let secondaryColor =
        props.selected === true ? 'rgba(67,87,231,0.3)' : 'rgba(81,99,113,0.3)';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42.776"
            height="42.911"
            viewBox="0 0 42.776 42.911"
        >
            <g transform="translate(0)">
                <path
                    d="M172.79,108.54s-9.977,1.844-11.7,9.576c-.669,3-.387,13.654-.387,13.654h3.383s-.677-12.979,1.933-15.105a15.473,15.473,0,0,1,6.517-2.711Z"
                    transform="translate(-154.698 -107.7)"
                    fill={primaryColor}
                />
                <ellipse
                    cx="6.247"
                    cy="6.247"
                    rx="6.247"
                    ry="6.247"
                    transform="translate(2.119 30.418)"
                    fill={secondaryColor}
                />
                <path
                    d="M112.437,402.447a5.09,5.09,0,0,1-3.112,4.725,4.642,4.642,0,0,1-5.26-1.357,5.072,5.072,0,0,1-.492-5.685,4.82,4.82,0,0,1,4.973-2.424,4.878,4.878,0,0,1,3.889,4.742.708.708,0,0,0,.654.678c.263.042.838-.062.833-.438a6.212,6.212,0,0,0-4.035-5.782,6.37,6.37,0,0,0-7.007,1.721,6.014,6.014,0,0,0-.62,6.934,6.454,6.454,0,0,0,6.607,2.979,6.089,6.089,0,0,0,5.056-5.852C113.935,401.932,112.447,401.713,112.437,402.447Z"
                    transform="translate(-101.438 -366.752)"
                    fill={primaryColor}
                />
                <path
                    d="M370.955,128.97C365.98,123.439,357,121.44,357,121.44l.005.006c2.818,1.343,5.918,3.9,8.561,8.689,5.464,9.9,3.213,23.636,2.052,28.863a1.613,1.613,0,0,1-1.136,1.2h0l3.341-.356a1.555,1.555,0,0,0,.745-.276,7.984,7.984,0,0,0,3.313-6.325C374.006,146.076,375.063,133.534,370.955,128.97Z"
                    transform="translate(-331.343 -119.305)"
                    fill={primaryColor}
                />
                <path
                    d="M279.288,110.562a21.1,21.1,0,0,0-8.206-8.628,14.72,14.72,0,0,0-6.024-1.729,13.4,13.4,0,0,0-2.217.039,2.235,2.235,0,0,0-1.948,1.5,4.859,4.859,0,0,0-.195,2.538,3.914,3.914,0,0,0,1.463,2.768c.7.428,1.448.78,2.139,1.241a17.961,17.961,0,0,1,5.6,5.848,14.988,14.988,0,0,1,1.807,4.975,57.111,57.111,0,0,1,.459,6.973,82.449,82.449,0,0,0,.683,9.284,5.914,5.914,0,0,0,3.777,5.173c.844.555,1.862,1.44,2.933,1.339a2.21,2.21,0,0,0,1.9-1.776c.3-1.262.513-2.546.716-3.824,1.193-7.53,1.315-15.746-1.568-22.92a25.749,25.749,0,0,0-1.326-2.8.506.506,0,0,0-.8-.155.9.9,0,0,0-.051,1.048c3.608,6.589,3.822,14.618,2.98,21.915q-.24,2.066-.615,4.116-.156.84-.334,1.677a1.787,1.787,0,0,1-.3.837,1.167,1.167,0,0,1-1.235.336,6.563,6.563,0,0,1-1.305-.765c-.455-.288-.9-.583-1.346-.892-.352-.246-.7-.5-1.029-.775a5.519,5.519,0,0,1-.438-.4c-.048-.048-.093-.1-.14-.148-.2-.208.056.09-.017-.016s-.123-.19-.183-.287c-.1-.168.05.133-.028-.056-.03-.07-.06-.14-.087-.21-.057-.15-.1-.3-.151-.456-.014-.045-.079-.3-.03-.1q-.041-.163-.075-.325a49.571,49.571,0,0,1-.7-7.687c-.11-2.162-.207-4.325-.31-6.488a19.318,19.318,0,0,0-1.015-5.658,18.05,18.05,0,0,0-5.1-7.386,21.551,21.551,0,0,0-2.676-2.008c-.339-.215-.684-.42-1.036-.612-.336-.185-.8-.336-1.017-.668a3.729,3.729,0,0,1-.442-2.5,1.324,1.324,0,0,1,1.336-1.168,12.992,12.992,0,0,1,4.879.483,16.6,16.6,0,0,1,8.6,6.39,27.446,27.446,0,0,1,1.807,2.856.506.506,0,0,0,.8.155A.88.88,0,0,0,279.288,110.562Z"
                    transform="translate(-244.633 -100.177)"
                    fill={primaryColor}
                />
                <path
                    d="M146.551,337.087V334.4a8.863,8.863,0,0,1,.014-1.083,2.253,2.253,0,0,1,1.258-1.765c1.455-.656,2.811.689,2.838,2.114.016.847,0,1.7,0,2.543v3.4l1.389-.346a11.376,11.376,0,0,0-6.076-2.695c-.334-.041-.794.068-.9.435-.109.35.24.631.555.671a9.714,9.714,0,0,1,5.154,2.216c.383.343,1.389.362,1.389-.345v-3.4a18.29,18.29,0,0,0-.042-3.031,3.559,3.559,0,0,0-4.224-2.786,3.487,3.487,0,0,0-2.854,3.479v3.345C145.045,337.9,146.551,337.843,146.551,337.087Z"
                    transform="translate(-140.671 -307.166)"
                    fill={primaryColor}
                />
                <path
                    d="M460.79,166.685l3.7,5.058s1.405-2.566.237-4.154S460.79,166.685,460.79,166.685Z"
                    transform="translate(-424.713 -159.876)"
                    fill={secondaryColor}
                />
                <path
                    d="M493.943,390.79q1.268-.929,2.591-1.78c.408-.262.267-1.187-.259-.848q-1.322.85-2.591,1.78c-.387.283-.243,1.216.259.848Z"
                    transform="translate(-454.106 -359.183)"
                    fill="#ecedee"
                />
                <path
                    d="M492.927,409.194q1.27-.93,2.591-1.78a.386.386,0,0,0,.118-.568c-.182-.189-.486-.092-.671.027q-1.322.851-2.591,1.78C491.844,409.042,492.468,409.532,492.927,409.194Z"
                    transform="translate(-452.944 -375.963)"
                    fill="#ecedee"
                />
                <path
                    d="M493.7,426q1.228-.9,2.51-1.721c.4-.258.14-1.225-.3-.942q-1.365.879-2.676,1.84c-.382.279,0,1.167.468.822Z"
                    transform="translate(-453.759 -390.85)"
                    fill="#ecedee"
                />
                <path
                    d="M118.2,449.739a4.576,4.576,0,0,0,1.659,3.492c.726.593,1.114-.75.55-1.212a2.749,2.749,0,0,1-1.059-2.124c-.005-.336-.213-.772-.585-.819S118.194,449.423,118.2,449.739Z"
                    transform="translate(-116.516 -414.045)"
                    fill={primaryColor}
                />
                <path
                    d="M441.7,155.006l3.7,5.058c.288.395.821-.049.968-.319.8-1.476,1.289-3.674.125-5.086-.982-1.19-2.887-1.277-4.284-.939-.685.167-.926,1.62-.087,1.418a3.681,3.681,0,0,1,3.279.5c.982.94.493,2.61-.037,3.591l.968-.319-3.7-5.06C442.171,153.22,441.279,154.426,441.7,155.006Z"
                    transform="translate(-407.442 -148.19)"
                    fill={primaryColor}
                />
            </g>
        </svg>
    );
}
