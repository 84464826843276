import React, { Fragment, useEffect, useState } from 'react';
import Play from '../icons/Play';
import Pause from '../icons/Pause';
import Load from '../icons/Load';
import './playerControllers.css';
export default function PlayerControllers(props) {
    const [playerState, setPlayerState] = useState();
    const handlePlay = () => {
        if (props.play) {
            props.play();
        }
    };
    const handlePause = () => {
        if (props.pause) {
            props.pause();
        }
    };
    useEffect(() => {
        setPlayerState(props.playerState);
    }, [props.playerState]);
    return (
        <div className="audio-actions">
            {playerState === 'paused' ? (
                <Fragment>
                    {props.audioLoaded ? (
                        <button
                            className="play"
                            aria-label="נגן"
                            onClick={handlePlay}
                        >
                            <span className="icon">
                                <Play />
                            </span>
                        </button>
                    ) : (
                        <Load />
                    )}
                </Fragment>
            ) : (
                <button
                    className="pause"
                    aria-label="עצור"
                    onClick={handlePause}
                >
                    <span className="icon">
                        <Pause />
                    </span>
                </button>
            )}
        </div>
    );
}
