import React from 'react';
import logo from '../../assets/images/logo.svg';
import './ReturningUser.css';
import ReturningUserDoodle from '../../components/ReturningUserDoodle';
import FooterActions from '../../components/FooterActions';

import { setParticipant, setParticipantMeta } from '../../helpers';

export default function ReturningUser(props) {
    const restartQuiz = () => {
        if (props.actionRestart) props.actionRestart();
    };
    const onContinue = () => {
        if (props.actionContinue) props.actionContinue();
    };
    return (
        <React.Fragment>
            <main className="screen returning-user">
                <div className="header">
                    <img src={logo} />
                </div>
                <div className="inner">
                    <ReturningUserDoodle />
                    <h1>!תודה רבה שחזרת אלינו</h1>
                    <p className="h1">
                        האם ברצונך להמשיך מהיכן שהפסקת או להתחיל מהתחלה?
                    </p>
                </div>
            </main>
            <FooterActions
                actionNext={onContinue}
                actionRestart={restartQuiz}
                canRestart="true"
            />
        </React.Fragment>
    );
}
