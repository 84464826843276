import React from 'react';

export default function Male() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74.213"
            height="72.932"
            viewBox="0 0 74.213 72.932"
        >
            <path
                d="M21.63,26.075V7.385l4.96,4.959a2.163,2.163,0,0,0,3.059-3.059L21,.634a2.163,2.163,0,0,0-3.059,0L9.285,9.285a2.163,2.163,0,0,0,3.059,3.059L17.3,7.385V26.076a19.543,19.543,0,0,0,2.163,38.957A19.535,19.535,0,0,0,21.63,26.075ZM19.467,60.707A15.213,15.213,0,1,1,34.752,45.422,15.231,15.231,0,0,1,19.467,60.707Z"
                transform="translate(47.562) rotate(47)"
                fill="#516371"
            />
        </svg>
    );
}
