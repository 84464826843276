import React from 'react';

export default function CochlearImplant(props) {
    let primaryColor = props.selected === true ? '#4357e7' : '#516371';
    let secondaryColor =
        props.selected === true ? 'rgba(67,87,231,0.3)' : 'rgba(81,99,113,0.3)';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48.529"
            height="43.907"
            viewBox="0 0 48.529 43.907"
        >
            <path
                d="M244.979,320.144a3.249,3.249,0,0,1-.1,1.849,4.473,4.473,0,0,1-2.152,1.732,8.718,8.718,0,0,1-5.636.64c-2.078-.438-3.857-1.624-5.752-2.521a16.645,16.645,0,0,0-5.938-1.736c-2.1-.142-4.427.39-5.183,2.61-.253.741.749,1.557,1.066.626.685-2.008,3.5-1.9,5.174-1.619a20.484,20.484,0,0,1,6.116,2.309,14.534,14.534,0,0,0,5.879,2.008,9.307,9.307,0,0,0,5.64-1.417,4.1,4.1,0,0,0,1.922-2.234A5.7,5.7,0,0,0,246,319.6a.58.58,0,0,0-.757-.431c-.354.17-.353.655-.27.97Z"
                transform="translate(-205.756 -296.059)"
                fill={primaryColor}
            />
            <path
                d="M110.221,357.187h2.854a2.339,2.339,0,0,0,2.2-3.126,11.453,11.453,0,0,0-1.719-3.191,13.107,13.107,0,0,0-5.177-4.19c-2.851-1.227-6.863-1.236-9.353.856a8.619,8.619,0,0,0-2.631,8.757,4.375,4.375,0,0,0,3.332,2.968.307.307,0,0,0,.243-.036c.119-.1-.987-1.582-1.1-1.807a6.936,6.936,0,0,1-.757-2.538c-.209-2.4,1.173-4.74,3.767-4.729a6.209,6.209,0,0,1,4.086,1.581,6.846,6.846,0,0,1,1.985,3.652A2.332,2.332,0,0,0,110.221,357.187Z"
                transform="translate(-94.087 -320.09)"
                fill={secondaryColor}
            />
            <path
                d="M223.717,478.169h-4.634a1.622,1.622,0,0,1-1.622-1.622v-2.468a1.26,1.26,0,0,1,1.259-1.259h5.361a1.26,1.26,0,0,1,1.259,1.259v2.468A1.622,1.622,0,0,1,223.717,478.169Z"
                transform="translate(-203.313 -434.319)"
                fill={secondaryColor}
            />
            <path
                d="M91,345.5h2.346a7.472,7.472,0,0,0,2.1-.118,2.827,2.827,0,0,0,2.023-2.259,6.018,6.018,0,0,0-1.067-3.387,13.611,13.611,0,0,0-2.5-3.227,12.255,12.255,0,0,0-6.259-3.333,10.157,10.157,0,0,0-6.814.985,8.874,8.874,0,0,0-3.9,5.193,7.188,7.188,0,0,0,.831,6.459,4.624,4.624,0,0,0,2.843,1.738c.485.075,1.305-.064,1.339-.7a1.876,1.876,0,0,0-.451-.952,8.2,8.2,0,0,1-1.2-2.309,5.033,5.033,0,0,1,.084-3.252,2.788,2.788,0,0,1,2.152-1.843,5.294,5.294,0,0,1,3.891,1.225,6.333,6.333,0,0,1,2.022,3.514A2.678,2.678,0,0,0,91,345.5a.907.907,0,0,0,.914-.517.507.507,0,0,0-.5-.648A1.562,1.562,0,0,1,89.92,343a8.925,8.925,0,0,0-.985-2.551,6.626,6.626,0,0,0-4.548-3.043,4.806,4.806,0,0,0-4.536,1.355,5.388,5.388,0,0,0-1.184,4.444,6.438,6.438,0,0,0,.933,2.545c.182.274.373.541.554.814.08.121.165.243.236.369.008.014.1.132.055.1.127.1-.152.255.048-.133l.529-.47c.088-.033.19.05.07-.021a1.024,1.024,0,0,0-.184-.041,3.091,3.091,0,0,1-.411-.14,3.426,3.426,0,0,1-.788-.476,4.19,4.19,0,0,1-1.143-1.421,6.2,6.2,0,0,1-.509-3.438,7.753,7.753,0,0,1,3.5-5.823,8.726,8.726,0,0,1,6.961-.377,12.308,12.308,0,0,1,6.2,5.12,10.9,10.9,0,0,1,1.041,2.147,1.985,1.985,0,0,1,.041,1.815c-.366.6-.932.564-1.556.564H91.412a.914.914,0,0,0-.914.517.5.5,0,0,0,.5.646Z"
                transform="translate(-76.541 -308.523)"
                fill={primaryColor}
            />
            <path
                d="M207.66,464.557h-6.6a2.544,2.544,0,0,0-.63,0,1.326,1.326,0,0,0,.209.347c.01-.007,0-.088.005-.1a2.441,2.441,0,0,0,.025-.47l.083-1.578.148-2.773-.62.793,7.354.175-.527-.5.132,2.385.087,1.6.031.556c.032.58-.061-.363.307-.438-.7.142-.855,1.636-.084,1.481,1.321-.267.88-2.166.826-3.141l-.161-2.926a.53.53,0,0,0-.527-.5l-7.354-.176c-.368-.008-.6.5-.619.793l-.137,2.573-.089,1.673a5.257,5.257,0,0,0-.028.887c.128,1.049,1.353.816,2.108.816h5.98C208.292,466.038,208.471,464.557,207.66,464.557Z"
                transform="translate(-187.133 -422.147)"
                fill={primaryColor}
            />
            <path
                d="M423.829,113.6a7.891,7.891,0,0,1-7.7,7.822,7.7,7.7,0,0,1-7.484-7.822,7.588,7.588,0,0,1,7.484-7.689A7.776,7.776,0,0,1,423.829,113.6Z"
                transform="translate(-375.299 -104.245)"
                fill={secondaryColor}
            />
            <path
                d="M405.475,97.651a7.4,7.4,0,0,1-4.647,6.757,6.549,6.549,0,0,1-7.52-2.309,7.552,7.552,0,0,1-.566-8.2,6.635,6.635,0,0,1,7.1-3.3,7.3,7.3,0,0,1,5.632,7.058c.007.742,1.506.6,1.5-.154a8.346,8.346,0,1,0-15.513,4.4,8.216,8.216,0,0,0,8.734,3.876,8.643,8.643,0,0,0,6.78-8.279C406.98,96.758,405.482,96.894,405.475,97.651Z"
                transform="translate(-358.799 -89.323)"
                fill={primaryColor}
            />
            <path
                d="M449.409,141.082a4.278,4.278,0,0,1-4.317,4.17,4.2,4.2,0,0,1-4.222-4.17A4.127,4.127,0,0,1,445.1,137,4.2,4.2,0,0,1,449.409,141.082Z"
                transform="translate(-404.293 -132.214)"
                fill="#ecedee"
            />
            <path
                d="M430.734,128.5a3.907,3.907,0,0,1-2.481,3.537,3.44,3.44,0,0,1-3.934-1.023,3.82,3.82,0,0,1-.327-4.289,3.73,3.73,0,0,1,3.744-1.783,3.6,3.6,0,0,1,3,3.558c.012.717,1.493.9,1.48.09a5,5,0,0,0-3.529-4.569,5.244,5.244,0,0,0-5.561,1.415,4.737,4.737,0,0,0-.376,5.591,5.263,5.263,0,0,0,5.376,2.328,4.957,4.957,0,0,0,4.09-4.765C432.226,127.874,430.744,127.684,430.734,128.5Z"
                transform="translate(-387.328 -120.287)"
                fill={primaryColor}
            />
            <path
                d="M447.29,118.6l.087,13.406a2.568,2.568,0,0,0,.262,1.131l1.259,2.92a.472.472,0,0,1,.046.2v2.811a.46.46,0,0,0,.46.46h.736a.46.46,0,0,0,.46-.46v-2.805a.455.455,0,0,1,.05-.209l1.391-2.729a2.563,2.563,0,0,0,.281-1.167V118.95a.46.46,0,0,0-.46-.46l-4.116-.35A.46.46,0,0,0,447.29,118.6Z"
                transform="translate(-410.069 -115.247)"
                fill={primaryColor}
            />
            <path
                d="M459.418,156.438a1.462,1.462,0,0,1-1.523,1.523,1.539,1.539,0,0,1-1.615-1.523,1.688,1.688,0,0,1,1.615-1.7A1.612,1.612,0,0,1,459.418,156.438Z"
                transform="translate(-418.156 -148.173)"
                fill="#ecedee"
            />
        </svg>
    );
}
