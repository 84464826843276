import React from 'react';

export default function Sun() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g transform="translate(-0.75 -0.75)">
                <path
                    d="M18,1.5V3.318"
                    transform="translate(-6.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M18,31.5v1.818"
                    transform="translate(-6.5 -11.818)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M6.33,6.33,7.621,7.621"
                    transform="translate(-1.903 -1.903)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M27.54,27.54l1.291,1.291"
                    transform="translate(-10.258 -10.258)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M1.5,18H3.318"
                    transform="translate(0 -6.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M31.5,18h1.818"
                    transform="translate(-11.818 -6.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M6.33,28.831,7.621,27.54"
                    transform="translate(-1.903 -10.258)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M27.54,7.621,28.831,6.33"
                    transform="translate(-10.258 -1.903)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    d="M19.591,15.045A4.545,4.545,0,1,1,15.045,10.5,4.545,4.545,0,0,1,19.591,15.045Z"
                    transform="translate(-3.545 -3.545)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
}
