import React from 'react';
import Play from '../icons/Play';

export default function AudioDescription(props) {
    return (
        <>
            <p style={{ textAlign: 'center' }}>
                *ניתן להיעזר בלחצני +/- לדיוק מרבי.
            </p>
            <p style={{ textAlign: 'center', marginTop: '0' }}>
                *יש ללחוץ על כפתור{' '}
                <span className="intext-icon">
                    <Play />
                </span>{' '}
                בכדי להאזין{props.speaker ? ` ל${props.speaker}` : ''}.
            </p>
        </>
    );
}
