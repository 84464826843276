import React from 'react';

export default function Ear(props) {
    let primaryColor = props.selected === true ? '#4357e7' : '#516371';
    let secondaryColor =
        props.selected === true ? 'rgba(67,87,231,0.3)' : 'rgba(81,99,113,0.3)';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.471"
            height="37.259"
            viewBox="0 0 24.471 37.259"
        >
            <g transform="translate(0 0)">
                <g transform="translate(0 0)">
                    <path
                        d="M687.857,81.834a7.506,7.506,0,0,0-8.932-6.269,4.815,4.815,0,0,0-2.823,7.972,2.306,2.306,0,0,0,1.124.788,1.938,1.938,0,0,0,1.569-.389c.658-.485,1.018-1.206,1.745-1.621a3.337,3.337,0,0,1,4.643,1.4c.812,1.619-.26,2.413-1.606,3.046a4.762,4.762,0,0,0-2.671,4.037c-.026.658.182,1.281.145,1.938a2.187,2.187,0,0,1-1.663,1.991,2.443,2.443,0,0,1-2.24-.475c-.563-.469-1.021-1.251-1.853-1.213-.877.041-1.536,1.061-1.884,1.755a5.541,5.541,0,0,0-.094,4.679,6.331,6.331,0,0,0,4.086,3.271,8.188,8.188,0,0,0,4.24.308,5.232,5.232,0,0,0,3.429-2.387c.844-1.475.7-3.156.618-4.782a7.962,7.962,0,0,1,1.162-4.836c1.121-1.858,2.742-3.366,3.967-5.155a10.671,10.671,0,0,0,1.865-4.484c.548-3.9-2.138-8.255-5.212-10.5a10.286,10.286,0,0,0-10.129-1.277c-3.859,1.783-3.639,7.076-2.466,11.118"
                        transform="translate(-668.283 -65.949)"
                        fill={secondaryColor}
                    />
                    <path
                        d="M650.145,55.975a8.163,8.163,0,0,0-3.807-5.685,7.92,7.92,0,0,0-6.726-.672c-3.786,1.481-5.747,6.681-2.727,9.857a2.42,2.42,0,0,0,3.219.449c.928-.564,1.414-1.687,2.5-1.96a2.852,2.852,0,0,1,2.946,1.085c.077.1.594,1.007.455,1.052a1.558,1.558,0,0,0,.094-.173c.047-.137-.135.134-.011.025a3.074,3.074,0,0,1-.564.395,7.942,7.942,0,0,0-2.677,2.047,6.657,6.657,0,0,0-1.368,3.343,12.115,12.115,0,0,0,.11,1.981,1.461,1.461,0,0,1-.027.475c-.045.146-.015.021.013-.013-.269.329-.037.065-.092.1-.981.672-1.974.082-2.7-.632a2.086,2.086,0,0,0-2.834-.46,5.991,5.991,0,0,0-2.1,2.9,6.417,6.417,0,0,0,1.228,6.755,8.914,8.914,0,0,0,6.612,2.35,6.706,6.706,0,0,0,5.854-4.1,9.294,9.294,0,0,0,.517-3.887,10.444,10.444,0,0,1,.185-3.426c.684-2.312,2.735-4.021,4.128-5.9a12.164,12.164,0,0,0,2.7-7.218,11.959,11.959,0,0,0-2.251-6.506c-2.632-3.839-7.174-6.314-11.89-5.381-4.535.9-7.733,5.1-9.025,9.323a15.823,15.823,0,0,0-.649,3.156c-.043.452.071,1.01.658.89A1.686,1.686,0,0,0,633,54.817c.337-3.555,2.07-7.281,5.318-9.034,3.736-2.025,8.488-.621,11.352,2.27a12.959,12.959,0,0,1,3.26,5.314,7.481,7.481,0,0,1-.622,5.915c-1.139,2.07-2.926,3.689-4.248,5.634a10.4,10.4,0,0,0-1.816,6.529,9.545,9.545,0,0,1-.135,3.156,3.138,3.138,0,0,1-1.794,1.884c-1.956.931-4.489.439-6.334-.542a4.854,4.854,0,0,1-2.787-4.783,3.1,3.1,0,0,1,.908-2.009c.682-.462,1.426.689,1.865,1.047,1.921,1.554,4.577.024,5.232-2.067a4.193,4.193,0,0,0,.135-1.788,4.552,4.552,0,0,1-.023-1.675,2.86,2.86,0,0,1,1.165-1.7,10.677,10.677,0,0,0,2.491-1.7,3.191,3.191,0,0,0,.7-2.935,3.89,3.89,0,0,0-5.27-2.369,4.119,4.119,0,0,0-1.293.861,9.534,9.534,0,0,1-1.03,1.063c-.939.665-1.782-.313-2.256-1.056a3.961,3.961,0,0,1-.639-2.141,2.72,2.72,0,0,1,.525-1.679c.129-.193-.182.219.07-.089l.163-.2a2.392,2.392,0,0,1,.292-.279,3.857,3.857,0,0,1,.948-.594,5.892,5.892,0,0,1,2.909-.4,7.058,7.058,0,0,1,5.314,3.2,7.15,7.15,0,0,1,1.042,2.795c.092.582.8.377,1.1.123a1.763,1.763,0,0,0,.614-1.595Z"
                        transform="translate(-631.256 -42.581)"
                        fill={primaryColor}
                    />
                </g>
            </g>
        </svg>
    );
}
