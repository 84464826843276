import React from 'react';
import ButtonNext from '../ButtonNext';
import coffee from '../../assets/images/coffee.svg';

import './FooterActions.css';
export default function FooterActions(props) {
    const handleRestart = () => {
        if (props.actionRestart) {
            props.actionRestart();
        }
    };
    const handleNext = () => {
        if (props.actionNext) {
            props.actionNext();
        }
    };
    const handleSkip = () => {
        if (props.actionSkip) {
            props.actionSkip();
        }
    };
    const handleBreak = () => {
        if (props.actionBreak) {
            props.actionBreak();
        }
    };
    return (
        <footer className="footer">
            <div className="container">
                {props.canRestart && (
                    <button className="btn btn-break" onClick={handleRestart}>
                        מהתחלה
                    </button>
                )}
                <ButtonNext
                    disabled={props.nextDisabled}
                    handleNext={handleNext}
                    text={props.nextText ? props.nextText : ''}
                />
                {props.canSkip && (
                    <button
                        className="btn btn-skip"
                        onClick={handleSkip}
                        disabled={props.skipDisabled}
                    >
                        אינני מרוצה מהתוצאה - דלג
                    </button>
                )}
                {props.showBreakButton && (
                    <button className="btn btn-break" onClick={handleBreak}>
                        <span className="icon">
                            <img
                                src={coffee}
                                alt="אייקון ספל קפה"
                                aria-hidden="true"
                            />
                        </span>
                        <span>הפסקה</span>
                    </button>
                )}
                {/* {props.startButton ? (
                    <button className="btn btn-default" onClick={handleNext}>
                        {props.startButtonTxt
                            ? props.startButtonTxt
                            : 'לשלב הבא'}
                    </button>
                ) : ( */}

                {/* )} */}
            </div>
        </footer>
    );
}
