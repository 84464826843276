import React, { useState, useEffect, Fragment } from 'react';
import { scrollTop } from '../../helpers';
import FooterActions from '../../components/FooterActions';
import Male from '../../components/icons/Male';
import Female from '../../components/icons/Female';

import Other from '../../components/icons/Other';
import './PersonalData.css';
export default function PersonalData(props) {
    const [age, setAge] = useState(0);
    const [gender, setGender] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const toggleDropdown = (e) => {
        const ageDropdown = document.querySelector('#age-dropdown');
        if (e.target.getAttribute('aria-expanded') === 'true') {
            e.target.setAttribute('aria-expanded', 'false');
        } else {
            e.target.setAttribute('aria-expanded', 'true');
        }
        ageDropdown.classList.toggle('show');
    };
    const selectAge = (e, i) => {
        const ageToggler = document.querySelector('#age-toggler');
        const ageDropdown = document.querySelector('#age-dropdown');
        ageDropdown.classList.remove('show');
        ageDropdown.querySelectorAll('li').forEach((elem) => {
            elem.classList.remove('active');
        });
        e.target.classList.add('active');
        setAge(i);

        ageToggler.querySelector('span.text').innerText = i;
        ageToggler.setAttribute('aria-expanded', 'false');
        if (gender !== null) {
            setButtonDisabled(false);
        }
    };
    const handleNext = async () => {
        setButtonDisabled(true);
        document.querySelector('#age-toggler').disabled = true;
        document.querySelectorAll('.gender').forEach((elem) => {
            elem.disabled = true;
        });

        await props.insertMultiAnswers({
            age: age,
            Gender: gender,
        });
        props.actionNext();
    };
    const ageList = [];
    const minAge = props.content.minAge ? props.content.minAge : 15;
    const maxAge = props.content.maxAge ? props.content.maxAge : 100;
    for (let i = minAge; i < maxAge; i++) {
        ageList.push(
            <li
                key={i}
                className={age === i ? 'active' : ''}
                data-value={i}
                onClick={(e) => {
                    selectAge(e, i);
                }}
            >
                {i}
            </li>
        );
    }

    const gendersObj = [
        {
            value: 'female',
            text: 'אישה',
        },
        {
            value: 'male',
            text: 'גבר',
        },
        {
            value: 'other',
            text: 'אחר',
        },
    ];

    const genders = gendersObj.map((g, i) => {
        return (
            <button
                key={i}
                className={
                    gender && gender === g.value ? 'gender active' : 'gender'
                }
                data-gender={g.value}
                onClick={(e) => {
                    if (age !== 0) {
                        setButtonDisabled(false);
                    }
                    setGender(g.value);
                }}
            >
                <span className="icon">
                    {(() => {
                        switch (g.value) {
                            case 'male':
                                return <Male />;
                            case 'female':
                                return <Female />;
                            case 'other':
                                return <Other />;
                            default:
                                return;
                        }
                    })()}
                </span>
                <span className="text">{g.text}</span>
            </button>
        );
    });

    useEffect(() => {
        let loaded = true;
        if (loaded) {
            scrollTop();
        }
        return function cleanup() {
            loaded = false;
        };
    }, []);

    return (
        <Fragment>
            <main className="main screen">
                <h2 className="h1">מהו גילך?</h2>
                <div className="age-wrap">
                    <button
                        id="age-toggler"
                        onClick={(e) => {
                            toggleDropdown(e);
                        }}
                        aria-controls="age-dropdown"
                        aria-expanded="false"
                    >
                        <span className="text">בחר</span>
                    </button>
                    <div id="age-dropdown">
                        <ul>{ageList}</ul>
                    </div>
                </div>
                <h2 className="h1">מגדר:</h2>
                <div className="gender-wrap">{genders}</div>
            </main>

            <FooterActions
                actionNext={handleNext}
                nextDisabled={buttonDisabled}
            />
        </Fragment>
    );
}
