import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Howl } from 'howler';
import { dbToLinear, findAvarage, scrollTop } from '../../helpers';
import { useInterval } from '../../hooks/useInterval';

import GenderDoodle from '../../components/GenderDoodle';
import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
import FooterActions from '../../components/FooterActions';
import './Audio.css';
import AudioDescription from '../../components/AudioDescription';

export default function AudioTimeStrech(props) {
    const debug = props.debug;
    const maxPlaybackRate = 1.5;
    const minPlaybackRate = 0.5;
    const step = 0.05;
    const gender = useRef();
    const speaker = gender.current === 'male' ? 'דובר' : 'דוברת';
    const [player, setPlayer] = useState(null);
    const [playerState, setPlayerState] = useState('paused');
    const [fileLoaded, setFileLoaded] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const fileRef = useRef(null);
    const [db, setDbValue] = useState(null);
    const [shouldIncreaseIndex, setShouldIncreaseIndex] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const [qNumber, setQNumber] = useState(1);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [pbrTiming, setPbrTiming] = useState([]);
    const [maleWPM, setMaleWPM] = useState([]);
    const [femaleWPM, setFemaleWPM] = useState([]);
    const [malePPS, setMalePPS] = useState([]);
    const [femalePPS, setFemalePPS] = useState([]);
    const audioIndex = useRef();
    const audioList = props.audio;
    const propsRef = props;
    const wpm = useRef();
    const pps = useRef();

    const handleNext = async () => {
        player.unload();
        setCanGoNext(false);
        setFileLoaded(false);
        const currentGender = gender.current;
        const isMale = currentGender === 'male';
        const finalWPM = Number(wpm.current * playbackRate).toFixed(2);
        const finalPPS = Number(pps.current * playbackRate).toFixed(1);
        if (isMale) {
            setMaleWPM([...maleWPM, Number(finalWPM)]);
            setMalePPS([...malePPS, Number(finalPPS)]);
        } else {
            setFemaleWPM([...femaleWPM, Number(finalWPM)]);
            setFemalePPS([...femalePPS, Number(finalPPS)]);
        }
        
        await props.insertMultiAnswers({
            [`${currentGender}WPM${qNumber}`]: Number(finalWPM),
            [`${currentGender}WPM${qNumber}(t)`]: pbrTiming,
            [`${currentGender}PPS${qNumber}`]: Number(finalPPS),
            [`${currentGender}TS${qNumber}File`]: fileRef.current.split('\\').pop().split('/').pop(),
        });
        setPbrTiming([]);

        if (props.content.last) {
            const finalAvgMale = maleWPM;
            const finalAvFemale = femaleWPM;
            const finalAvgMalePPS = malePPS;
            const finalAvgFemalePPS = femalePPS;
            if (isMale) {
                finalAvgMale.push(Number(finalWPM));
                finalAvgMalePPS.push(Number(finalPPS));
            } else {
                finalAvFemale.push(Number(finalWPM));
                finalAvgFemalePPS.push(Number(finalPPS));
            }
            await props.insertMultiAnswers({
                maleAvgWPM: Number(
                    Number(findAvarage(finalAvgMale)).toFixed(2)
                ),
                femaleAvgWPM: Number(
                    Number(findAvarage(finalAvFemale)).toFixed(2)
                ),
                maleAvgPPS: Number(
                    Number(findAvarage(finalAvgMalePPS)).toFixed(1)
                ),
                femaleAvgPPS: Number(
                    Number(findAvarage(finalAvgFemalePPS)).toFixed(1)
                ),
            });
        }

        if (shouldIncreaseIndex) {
            if (isMale) {
                await propsRef.increaseGenderIndex('male');
            } else {
                await propsRef.increaseGenderIndex('female');
            }
        }
        setPlayer(null);
        setAudioFile(null);
        
        if (!isMale) setQNumber(n => n + 1);

        propsRef.actionNext();
    };

    const increaseVoiceOverIndex = useCallback(() => {
        const currentGender = gender.current
        if (currentGender === 'male') {
            propsRef.increaseGenderIndex('male');
        } else {
            propsRef.increaseGenderIndex('female');
        }
        audioIndex.current++;

        const currentFile = audioList[currentGender][audioIndex.current]
        setAudioFile(currentFile.location);
        fileRef.current = currentFile.location;
        wpm.current = Number(currentFile.wpm);
        pps.current = Number(currentFile.pps);
        setShouldIncreaseIndex(false);
        setFileLoaded(false);
    }, [propsRef, audioList]);

    useInterval(() => {
        if (player !== null && player.playing() && fileRef.current !== null) {
            const tmp = pbrTiming;
            const index = tmp.findIndex((p) => p.file === fileRef.current);
            const wpmValue = Number(wpm.current * playbackRate).toFixed(2);
            if (index === -1) {
                tmp.push({ file: fileRef.current, timing: [] });
                tmp[tmp.length - 1].timing = [
                    { 'T(t)': player.seek(), 'WPM(t)': Number(wpmValue) },
                ];
            } else {
                tmp[index].timing = [
                    ...tmp[index].timing,
                    { 'T(t)': player.seek(), 'WPM(t)': Number(wpmValue) },
                ];
            }
            setPbrTiming(tmp);
        }
    }, 200);

    useEffect(() => {
        if (gender.current !== props.content.gender) {
            scrollTop();
            const currentGender = props.content.gender;
            const isMale = currentGender === 'male';
            gender.current = currentGender;
            
            const index = isMale ? props.maleIndex : props.femaleIndex;
            
            audioIndex.current = index
        
            const currentFile = audioList[currentGender][index];
            
            setAudioFile(currentFile.location);
            
            fileRef.current = currentFile.location;
            wpm.current = Number(currentFile.wpm);
            pps.current = Number(currentFile.pps);
            setFileLoaded(false);
            setPlaybackRate(1);
            setDbValue(isMale ? props.sm : props.sf);
        }
        return function cleanup() {
            setAudioFile(null);
        };
    }, [
        props.content.gender,
        audioList,
        props.maleIndex,
        props.femaleIndex,
        audioFile,
        props.sm,
        props.sf,
    ]);

    useEffect(() => {
        if (player && player.state && player.state('loaded')) {
            player.volume(dbToLinear(db));
            if (!debug) {
                return;
            }
            console.log(`Player Volume: Linear - ${dbToLinear(db)}, dB: ${db}`);
        }
    }, [player, db, debug]);

    useEffect(() => {
        if (player && player.state && player.state('loaded')) {
            player.rate(playbackRate);
            if (!debug) {
                return;
            }
            console.log(
                `Initital WPM: ${
                    wpm.current
                }, Playback Rate: ${playbackRate}, Current WPM: ${
                    wpm.current * playbackRate
                }, Current PPS: ${pps.current * playbackRate}`
            );
        }
    }, [player, playbackRate, debug]);

    useEffect(() => {
        if (audioFile !== null) {
            setPlayer(
                new Howl({
                    src: audioFile,
                    html5: true,
                    onload: () => {
                        setFileLoaded(true);
                        if (!debug) {
                            return;
                        }
                        console.log(
                            `Player Loaded. Current File: ${audioFile}`
                        );
                    },
                    onplay: () => {
                        setPlayerState('playing');
                        setShouldIncreaseIndex(true);
                        setCanGoNext(true);
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                        increaseVoiceOverIndex();
                    },
                })
            );
        }
    }, [audioFile, increaseVoiceOverIndex, debug]);

    // useEffect(() => {
    //     let loaded = true;
    //     if (loaded) {
    //         scrollTop();
    //     }
    //     return function cleanup() {
    //         loaded = false;
    //     };
    // }, []);

    return (
        <React.Fragment>
            <main className="main screen">
                <GenderDoodle gender={gender.current} />
                <h1 className="screen-title">
                    כיוון קצב דיבור מרבי להבנת {speaker}
                </h1>

                <p style={{ textAlign: 'center' }}>
                    בהישמע ה{speaker}, יש להזיז הנקודה הכחולה ימינה ושמאלה
                    בהתאם, עד לנקודה בה{' '}
                    <strong>
                        הדיבור הינו במהירות המרבית בה מתאפשרת הבנה ברורה של
                        התוכן הנאמר
                    </strong>
                    .
                </p>

                <AudioDescription speaker={speaker} />
                <PlayerControllers
                    playerState={playerState}
                    audioLoaded={fileLoaded}
                    play={() => {
                        if (player) {
                            player.play();
                        }
                    }}
                    pause={() => {
                        if (player) {
                            player.pause();
                        }
                    }}
                />
                <Slider
                    min={minPlaybackRate}
                    max={maxPlaybackRate}
                    step={step}
                    labelIncrease="הגבר את קצב הדיבור"
                    labelDecrease="הנמך את קצב הדיבור"
                    playerState={playerState}
                    value={playbackRate}
                    sliderAction={(value) => {
                        setPlaybackRate(value);
                    }}
                    increaseAction={() => {
                        setPlaybackRate(
                            maxPlaybackRate <= Number(playbackRate) + step
                                ? maxPlaybackRate
                                : Number(playbackRate) + step
                        );
                    }}
                    decreaseAction={() => {
                        setPlaybackRate(
                            minPlaybackRate >= Number(playbackRate) - step
                                ? minPlaybackRate
                                : Number(playbackRate) - step
                        );
                    }}
                />

                <DoNotChangeNotice />
            </main>

            <FooterActions
                actionNext={handleNext}
                nextDisabled={canGoNext === true ? false : true}
            />
        </React.Fragment>
    );
}
