import React from 'react';

export default function DecText() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.635"
            height="14.69"
            viewBox="0 0 16.635 14.69"
        >
            <g transform="translate(-91 -234.115)">
                <g transform="translate(97.422 234.115)">
                    <path
                        d="M4.667,0V-11.364H.422v-1.521H10.635v1.521H6.372V0Z"
                        transform="translate(-0.422 12.885)"
                        fill="#0c0b26"
                    />
                </g>
                <path
                    d="M3.628,3.35v.558a.21.21,0,0,0,.209.209H6.769a.21.21,0,0,0,.209-.209V3.35a.21.21,0,0,0-.209-.209H3.838A.21.21,0,0,0,3.628,3.35ZM.122,8.317l.494.494a.417.417,0,0,0,.591,0L2.948,7.071a.418.418,0,0,0,.122-.3V6.49a3.627,3.627,0,1,0-.628-.628H2.158a.418.418,0,0,0-.3.122L.122,7.723A.422.422,0,0,0,.122,8.317ZM2.931,3.629A2.373,2.373,0,1,1,5.3,6,2.371,2.371,0,0,1,2.931,3.629Z"
                    transform="translate(91 239.872)"
                />
            </g>
        </svg>
    );
}
