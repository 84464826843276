import React from 'react';

export default function Logo(props) {
    let color = props.mode === 'dark' ? '#ffffff' : '#131224';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="50.142"
            viewBox="0 0 100 50.142"
        >
            <g transform="translate(0)">
                <path
                    d="M50.8,66.4a.218.218,0,0,1,.212.248c-.423,3.022-3.022,5.494-7.253,5.494-5.053,0-7.761-3.391-7.761-8.184,0-4.98,2.9-8.274,7.67-8.274,4.884,0,7.634,3.294,7.724,8.673a.26.26,0,0,1-.242.242H39.669c.139,3.367,1.511,4.95,4.189,4.95a3.361,3.361,0,0,0,3.626-2.9.332.332,0,0,1,.308-.248Zm-7.114-8.154c-2.2,0-3.512,1.342-3.911,3.88h7.634C47.26,60.1,46.1,58.243,43.688,58.243Z"
                    transform="translate(-14.241 -22.026)"
                    fill={color}
                />
                <path
                    d="M71.1,69.1a.3.3,0,0,1-.242.308,8.776,8.776,0,0,1-2.539.453c-2.472,0-4.7-1.251-4.7-4.763l.036-8.48H61.838a.266.266,0,0,1-.248-.242V54.1a.272.272,0,0,1,.248-.248h1.813V50.162a.26.26,0,0,1,.242-.242H67.1a.26.26,0,0,1,.242.242v3.693h3.578a.266.266,0,0,1,.242.248v2.261a.26.26,0,0,1-.242.242H67.278v8.4c0,1.59.671,2.079,1.813,2.079a6.686,6.686,0,0,0,1.747-.248.193.193,0,0,1,.242.181Z"
                    transform="translate(-24.364 -19.748)"
                    fill={color}
                />
                <path
                    d="M87.7,69.1a.3.3,0,0,1-.242.308,8.734,8.734,0,0,1-2.533.453c-2.478,0-4.708-1.251-4.708-4.763V56.6H78.4a.26.26,0,0,1-.242-.242V54.1a.266.266,0,0,1,.242-.248h1.813V50.162a.266.266,0,0,1,.248-.242h3.2a.26.26,0,0,1,.248.242l-.03,3.693h3.572a.266.266,0,0,1,.242.248v2.261a.26.26,0,0,1-.242.242H83.884v8.4c0,1.59.671,2.079,1.813,2.079a6.649,6.649,0,0,0,1.741-.248.193.193,0,0,1,.242.181Z"
                    transform="translate(-30.919 -19.748)"
                    fill={color}
                />
                <path
                    d="M108.95,66.4a.218.218,0,0,1,.218.248c-.429,3.022-3.022,5.494-7.253,5.494-5.071,0-7.785-3.391-7.785-8.184,0-4.98,2.9-8.274,7.664-8.274,4.89,0,7.634,3.294,7.73,8.673a.266.266,0,0,1-.248.242H97.793c.151,3.361,1.529,4.95,4.183,4.95a3.367,3.367,0,0,0,3.626-2.9.326.326,0,0,1,.3-.248Zm-7.114-8.154c-2.2,0-3.512,1.342-3.911,3.88h7.64C105.408,60.1,104.248,58.243,101.836,58.243Z"
                    transform="translate(-37.236 -22.026)"
                    fill={color}
                />
                <path
                    d="M132.645,72.122a.26.26,0,0,1-.242-.242l.06-2.23a5.609,5.609,0,0,1-4.835,2.472c-4.37,0-6.649-3.542-6.649-8.123s2.5-8.335,6.721-8.335a5.591,5.591,0,0,1,4.763,2.418l-.091-2.2a.26.26,0,0,1,.242-.242h3.24a.26.26,0,0,1,.242.242l-.091,8,.1,8a.26.26,0,0,1-.242.242Zm-4.031-2.593c2.478,0,3.911-1.813,3.941-5.47s-1.372-5.651-3.85-5.681c-2.684-.03-4,2.019-4,5.379C124.7,67.42,126.051,69.529,128.614,69.529Z"
                    transform="translate(-47.858 -22.01)"
                    fill={color}
                />
                <path
                    d="M148.968,72.138a.26.26,0,0,1-.248-.242l.091-7.972-.091-8a.26.26,0,0,1,.248-.242h3.173a.266.266,0,0,1,.248.242l-.1,2.962a6.576,6.576,0,0,1,5.47-3.2,6.449,6.449,0,0,1,.822.06.357.357,0,0,1,.248.3v2.381a.236.236,0,0,1-.248.218,5.633,5.633,0,0,0-.731,0c-2.962,0-5.343,2.043-5.44,5.621l.03,7.664a.266.266,0,0,1-.248.242Z"
                    transform="translate(-58.831 -22.026)"
                    fill={color}
                />
                <path
                    d="M11.291,56.783a8.051,8.051,0,0,1-7.857-6.225l-.411-1.638,3.276-.81.4,1.638a4.714,4.714,0,0,0,2.539,3.2,4.787,4.787,0,0,0,4.068,0,6.2,6.2,0,0,0,3.367-4.9,6.914,6.914,0,0,0-1.046-4.412c-.163-.29-.387-.6-.6-.955-.858-1.263-2.031-3.022-1.5-5.125a6.093,6.093,0,0,1,1.5-2.569c.369-.4.749-.816,1.118-1.209,1.517-1.6,2.943-3.119,3.4-4.98a6.649,6.649,0,0,0-1.263-5.645,8.19,8.19,0,0,0-7.035-2.877,8.039,8.039,0,0,0-6.407,3.838c-1.813,3.113-1.614,7.023-1.112,9.761l.3,1.656-3.318.6-.3-1.656C-.2,31.18-.423,26.4,1.91,22.385a11.405,11.405,0,0,1,9.066-5.476,11.556,11.556,0,0,1,9.918,4.116,10.088,10.088,0,0,1,1.916,8.613c-.659,2.72-2.551,4.727-4.231,6.491-.375.393-.725.768-1.058,1.142a3.191,3.191,0,0,0-.749,1.124c-.157.6.284,1.336,1.021,2.418.26.387.526.78.762,1.209a10.305,10.305,0,0,1,1.451,6.54A9.544,9.544,0,0,1,14.7,56.057a8.208,8.208,0,0,1-3.409.725Z"
                    transform="translate(0 -6.671)"
                    fill={color}
                />
                <path
                    d="M49.8,31.23l-2.617-1.511.756-1.312a17.909,17.909,0,0,0,1.65-14.016A18.132,18.132,0,0,0,40.77,3.373l-1.33-.707L40.86,0,42.2.707a21.33,21.33,0,0,1,8.359,29.217Z"
                    transform="translate(-15.602)"
                    fill="#1b80ef"
                />
                <path
                    d="M40.578,32.146l-2.611-1.511.756-1.312A11.81,11.81,0,0,0,39.8,20.076a11.992,11.992,0,0,0-5.814-7.253l-1.33-.707L34.074,9.45l1.336.713a15.11,15.11,0,0,1,5.917,20.7Z"
                    transform="translate(-12.92 -3.738)"
                    fill="#1b80ef"
                />
            </g>
        </svg>
    );
}
