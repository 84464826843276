import React, { Component, Fragment } from 'react';
import Header from './components/Header/';
import ProgressBar from './components/ProgressBar/';
import ProgressText from './components/ProgressText/';
import AccessibilityPanel from './components/AccessibilityPanel/';
import ScreenText from './views/ScreenText';
import ExitPopup from './views/ExitPopup';
import SelectableList from './views/SelectableList/';
import PersonalData from './views/PersonalData/';
import AudioVolume from './views/AudioVolume';
import AudioTimeStrech from './views/AudioTimeStrech';
import AudioSNR from './views/AudioSNR';
import AudioSNRTS from './views/AudioSNRTS/index';
import VolumeRange from './views/VolumeRange';
import VolumeRangeToNoise from './views/VolumeRangeToNoise/index';
import ReturningUser from './views/ReturningUser';
import NoiseEffect from './views/NoiseEffect';
import Break from './views/Break';
import Band from './views/Band';
import End from './views/End';

import {
    uuid,
    setCookies,
    getCookies,
    getParticipantMetadata,
    setParticipantMeta,
    setParticipant,
    fetchSounds,
} from './helpers';

import './assets/css/App.css';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 0,
            currentSection: 1,
            content: [],
            sounds: {},
            nextMaleAudioIndex: 0,
            nextFemaleAudioIndex: 0,
            answers: {},
            showExit: false,
            returningUser: false,
            showReturnPopup: false,
            uuid: null,
            breaks: [17, 28, 35, 42, 45, 50],
            hideBreakPopup: false,
            mode: 'light',
        };
        this.handleSetMode = this.handleSetMode.bind(this);
        this.handleMoveToNextScreen = this.handleMoveToNextScreen.bind(this);
        this.handleExitPopup = this.handleExitPopup.bind(this);
        this.handleRestartQuiz = this.handleRestartQuiz.bind(this);
        this.handleRemoveBreakPopup = this.handleRemoveBreakPopup.bind(this);
        this.insertMultiAnswers = this.insertMultiAnswers.bind(this);
        this.handleContinueQuiz = this.handleContinueQuiz.bind(this);
        this.handleIncreaseMaleAudioIndex = this.handleIncreaseMaleAudioIndex.bind(
            this
        );
        this.handleIncreaseFemaleAudioIndex = this.handleIncreaseFemaleAudioIndex.bind(
            this
        );
        this.increaseGenderIndex = this.increaseGenderIndex.bind(this);
        this.incrementSection = this.incrementSection.bind(this);
    }
    async componentDidMount() {
        var queryParams = new URLSearchParams(window.location.search);
        const session = await getCookies();
        const cookies = await session.data;
        const debug = queryParams.get('debug');
        this.setState({debug: debug === '' || debug === 'true'})

        if (cookies.uuid) {
            this.setState({
                uuid: cookies.uuid,
                sounds: cookies.sounds,
                currentScreen: cookies.currentScreen,
                content: cookies.content,
                answers: cookies.answers,
                nextMaleAudioIndex: cookies.nextMaleAudioIndex,
                nextFemaleAudioIndex: cookies.nextFemaleAudioIndex,
            });
        } else if (queryParams.get('uuid')) {
            this.setState({
                returningUser: true,
                showReturnPopup: true,
                uuid: queryParams.get('uuid'),
            });
            setCookies({ uuid: queryParams.get('uuid') });
        } else {
            const userUid = uuid();
            await setCookies({ uuid: userUid });
            await setParticipant(userUid, null);
            this.setState({
                uuid: userUid,
            });

            const sounds = await fetchSounds();
            this.setState({ sounds });
            await setParticipantMeta(userUid, 'sounds', sounds);
            let now = new Date(Date.now());
            await setParticipantMeta(
                userUid,
                'startDateTime',
                now.toLocaleString('en-GB', { timeZone: 'Asia/Jerusalem' })
            );
            await setCookies({
                currentScreen: 0,
                nextMaleAudioIndex: 0,
                nextFemaleAudioIndex: 0,
                sounds,
                answers: this.state.answers,
            });
        }
        if (!cookies.content) {
            fetch('./db/content.json', { mode: 'no-cors' })
                .then((response) => response.json())
                .then(async (content) => {
                    await setCookies({ content });
                    this.setState({
                        content,
                    });
                });
        }
    }

    handleSetMode(mode) {
        this.setState({ mode });
    }

    incrementSection() {
        this.setState({ currentSection: this.state.currentSection + 1 });
    }

    async insertMultiAnswers(answers) {
        const newAnswers = { ...this.state.answers, ...answers };

        await setParticipantMeta(this.state.uuid, 'answers', newAnswers);
        let now = new Date();
        await setParticipantMeta(
            this.state.uuid,
            'endDateTime',
            now.toLocaleString('en-GB', { timeZone: 'Asia/Jerusalem' })
        );
        const serverRes = await setCookies({ answers: newAnswers });

        const data = await serverRes.data;
        if (data) {
            this.setState({ answers: newAnswers });
        }
        return;
    }

    async handleMoveToNextScreen(screenId) {
        let currentScreen;
        if (screenId !== undefined && screenId !== '') {
            currentScreen = screenId;
        } else {
            currentScreen =
                this.state.currentScreen < this.state.content.length
                    ? this.state.currentScreen + 1
                    : this.state.content.length;
        }
        const done = await setCookies({
            currentScreen,
        });
        const data = await done.data;
        if (data) {
            this.setState({
                currentScreen,
            });
        }

        const hideBreakPopup = this.state.hideBreakPopup;
        if (hideBreakPopup) {
            this.setState({ hideBreakPopup: false });
        }
    }

    async increaseGenderIndex(gender) {
        if (gender === 'male') {
            await this.handleIncreaseMaleAudioIndex();
        }
        if (gender === 'female') {
            await this.handleIncreaseFemaleAudioIndex();
        }
    }
    async handleIncreaseMaleAudioIndex() {
        const audioIndex =
            this.state.nextMaleAudioIndex + 1 === this.state.sounds.male.length
                ? 0
                : this.state.nextMaleAudioIndex + 1;
        const serverRes = await setCookies({ nextMaleAudioIndex: audioIndex });
        const data = await serverRes.data;
        if (data) {
            this.setState({
                nextMaleAudioIndex: audioIndex,
            });
        }
    }
    async handleIncreaseFemaleAudioIndex() {
        const audioIndex =
            this.state.nextFemaleAudioIndex + 1 ===
            this.state.sounds.female.length
                ? 0
                : this.state.nextFemaleAudioIndex + 1;
        const serverRes = await setCookies({
            nextFemaleAudioIndex: audioIndex,
        });
        const data = await serverRes.data;
        if (data) {
            this.setState({
                nextFemaleAudioIndex: audioIndex,
            });
        }
    }

    handleExitPopup() {
        const showExit = this.state.showExit === true ? false : true;
        this.setState({ showExit });
    }
    handleRemoveBreakPopup() {
        this.setState({ hideBreakPopup: true });
    }
    async handleContinueQuiz() {
        const res = await getParticipantMetadata(this.state.uuid);
        const medata = await res.data;
        let data = await medata.filter((meta) => {
            return meta.meta_key === 'currentScreen';
        });
        const currentScreen = await data[0].meta_value;

        data = await medata.filter((meta) => {
            return meta.meta_key === 'sounds';
        });
        const sounds = await data[0].meta_value;

        data = await medata.filter((meta) => {
            return meta.meta_key === 'answers';
        });
        const answers = await data[0].meta_value;
        this.setState({
            answers: JSON.parse(answers),
            sounds: JSON.parse(sounds),
            currentScreen: Number(currentScreen),
            showReturnPopup: false,
        });
    }
    async handleRestartQuiz() {
        const sounds = await fetchSounds();
        await setCookies({ sounds });
        await setCookies({ currentScreen: 0 });
        this.setState({
            currentScreen: 0,
            showReturnPopup: false,
            sounds,
        });
    }
    render() {
        const item = this.state.content[this.state.currentScreen];
        let type = item ? item.type : '';
        let questionNumber =
            item && item.questionNumber ? item.questionNumber : '';
        if (questionNumber !== '') {
            questionNumber = questionNumber.split(':');
        }
        let progress =
            (this.state.currentScreen + 1) / this.state.content.length;
        const canPause = item && item.canPause === false ? false : true;
        const screens = () => {
            switch (type) {
                case 'text':
                    return (
                        <ScreenText
                            content={item}
                            actionNext={this.handleMoveToNextScreen}
                            debug={this.state.debug}
                        />
                    );
                case 'selectableList':
                    return (
                        <SelectableList
                            content={item}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'personalData':
                    return (
                        <PersonalData
                            content={item}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'break':
                    return (
                        <Break
                            content={item}
                            actionNext={this.handleMoveToNextScreen}
                            incrementSection={this.incrementSection}
                            currentSection={this.state.currentSection}
                            debug={this.state.debug}
                        />
                    );
                case 'audioVolume':
                    return (
                        <AudioVolume
                            content={item}
                            increaseGenderIndex={this.increaseGenderIndex}
                            audio={{
                                male: this.state.sounds.male,
                                female: this.state.sounds.female,
                            }}
                            maleIndex={this.state.nextMaleAudioIndex}
                            femaleIndex={this.state.nextFemaleAudioIndex}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'audioTimeStrech':
                    return (
                        <AudioTimeStrech
                            content={item}
                            increaseGenderIndex={this.increaseGenderIndex}
                            audio={{
                                male: this.state.sounds.male,
                                female: this.state.sounds.female,
                            }}
                            maleIndex={this.state.nextMaleAudioIndex}
                            femaleIndex={this.state.nextFemaleAudioIndex}
                            actionNext={this.handleMoveToNextScreen}
                            sm={this.state.answers.Sm}
                            sf={this.state.answers.Sf}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'audioSNR':
                    return (
                        <AudioSNR
                            content={item}
                            increaseGenderIndex={this.increaseGenderIndex}
                            male={this.state.sounds.male}
                            female={this.state.sounds.female}
                            audio={this.state.sounds}
                            maleIndex={this.state.nextMaleAudioIndex}
                            femaleIndex={this.state.nextFemaleAudioIndex}
                            actionNext={this.handleMoveToNextScreen}
                            sm={this.state.answers.Sm}
                            sf={this.state.answers.Sf}
                            debug={this.state.debug}
                            insertMultiAnswers={this.insertMultiAnswers}
                        />
                    );
                case 'audioSNRTS':
                    return (
                        <AudioSNRTS
                            content={item}
                            sm={this.state.answers.Sm}
                            sf={this.state.answers.Sf}
                            avgSNRm={this.state.answers.avgSNRm}
                            avgSNRf={this.state.answers.avgSNRf}
                            increaseGenderIndex={this.increaseGenderIndex}
                            audio={this.state.sounds}
                            maleIndex={this.state.nextMaleAudioIndex}
                            femaleIndex={this.state.nextFemaleAudioIndex}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'band':
                    return (
                        <Band
                            content={item}
                            sm={this.state.answers.Sm}
                            lm={this.state.answers.Lm}
                            sf={this.state.answers.Sf}
                            lf={this.state.answers.Lf}
                            avgSNRm={this.state.answers.avgSNRm}
                            avgSNRf={this.state.answers.avgSNRf}
                            audio={this.state.sounds.singers}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'volumeRange':
                    return (
                        <VolumeRange
                            content={item}
                            sm={this.state.answers.Sm}
                            lm={this.state.answers.Lm}
                            sf={this.state.answers.Sf}
                            lf={this.state.answers.Lf}
                            audio={this.state.sounds.voices}
                            insertMultiAnswers={this.insertMultiAnswers}
                            actionNext={this.handleMoveToNextScreen}
                            debug={this.state.debug}
                        />
                    );
                case 'volumeRangeToNoise':
                    return (
                        <VolumeRangeToNoise
                            content={item}
                            sm={this.state.answers.Sm}
                            sf={this.state.answers.Sf}
                            audio={this.state.sounds.voices}
                            audioNoises={this.state.sounds.noises}
                            initialDb={{
                                Swhisp: this.state.answers.Swhisp,
                                Sm: this.state.answers.Sm,
                                Sshout: this.state.answers.Sshout,
                            }}
                            Swhisp={this.state.answers.Swhisp}
                            Ssp={this.state.answers.Ssp}
                            Srasp={this.state.answers.Srasp}
                            Sshout={this.state.answers.Sshout}
                            insertMultiAnswers={this.insertMultiAnswers}
                            actionNext={this.handleMoveToNextScreen}
                            debug={this.state.debug}
                        />
                    );
                case 'noiseEffect':
                    return (
                        <NoiseEffect
                            content={item}
                            sm={this.state.answers.Sm}
                            nsp={this.state.answers.Nsp}
                            audio={this.state.sounds.effects}
                            actionNext={this.handleMoveToNextScreen}
                            insertMultiAnswers={this.insertMultiAnswers}
                            debug={this.state.debug}
                        />
                    );
                case 'end':
                    return <End finalState={this.state}
                    debug={this.state.debug} />;
                default:
                    return;
            }
        };
        return (
            <Fragment>
                <AccessibilityPanel setMode={this.handleSetMode} />
                <div className="App">
                    {this.state.returningUser && this.state.showReturnPopup ? (
                        <ReturningUser
                            actionContinue={this.handleContinueQuiz}
                            actionRestart={this.handleRestartQuiz}
                        />
                    ) : (
                        <>
                            <Fragment>
                                <Header
                                    canPause={canPause}
                                    showExit={this.handleExitPopup}
                                    mode={this.state.mode}
                                />
                                {item &&
                                    item.hasSlider &&
                                    !this.state.showExit && (
                                        <ProgressBar
                                            progress={progress * 100}
                                        />
                                    )}

                                {((item && item.section) ||
                                    questionNumber !== '') &&
                                    !this.state.showExit && (
                                        <ProgressText
                                            section={item.section}
                                            questionNumber={questionNumber}
                                        />
                                    )}
                            </Fragment>
                            <Fragment>
                                {this.state.showExit ? (
                                    <ExitPopup
                                        uuid={this.state.uuid}
                                        answers={this.state.answers}
                                        sounds={this.state.sounds}
                                        currentScreen={this.state.currentScreen}
                                        stateData={this.state}
                                    />
                                ) : (
                                    <Fragment>
                                        {this.state.breaks.includes(
                                            this.state.currentScreen
                                        ) && !this.state.hideBreakPopup ? (
                                            <Break
                                                actionNext={
                                                    this.handleRemoveBreakPopup
                                                }
                                                incrementSection={
                                                    this.incrementSection
                                                }
                                                currentSection={
                                                    this.state.currentSection
                                                }
                                            />
                                        ) : (
                                            screens()
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        </>
                    )}
                </div>
            </Fragment>
        );
    }
}
