import React from 'react';

export default function Female() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39.077"
            height="65.033"
            viewBox="0 0 39.077 65.033"
        >
            <path
                d="M39.077,19.611a19.539,19.539,0,1,0-21.63,19.348v13.1H10.959a2.163,2.163,0,1,0,0,4.326h6.489V62.87a2.163,2.163,0,1,0,4.326,0V56.381h6.489a2.163,2.163,0,1,0,0-4.326H21.774v-13.1a19.494,19.494,0,0,0,17.3-19.346Zm-34.752,0A15.213,15.213,0,1,1,19.611,34.752,15.3,15.3,0,0,1,4.326,19.611Z"
                fill="#516371"
            />
        </svg>
    );
}
