import React from 'react';

export default function Other() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74.213"
            height="90.585"
            viewBox="0 0 74.213 90.585"
        >
            <g transform="translate(0)">
                <path
                    d="M39.077,19.611a19.539,19.539,0,1,0-21.63,19.348v13.1H10.959a2.163,2.163,0,1,0,0,4.326h6.489V62.87a2.163,2.163,0,1,0,4.326,0V56.381h6.489a2.163,2.163,0,1,0,0-4.326H21.774v-13.1a19.494,19.494,0,0,0,17.3-19.346Zm-34.752,0A15.213,15.213,0,1,1,19.611,34.752,15.3,15.3,0,0,1,4.326,19.611Z"
                    transform="translate(8.607 25.552)"
                    fill="#516371"
                />
                <path
                    d="M21.63,26.075V7.385l4.96,4.959a2.163,2.163,0,0,0,3.059-3.059L21,.634a2.163,2.163,0,0,0-3.059,0L9.285,9.285a2.163,2.163,0,0,0,3.059,3.059L17.3,7.385V26.076a19.543,19.543,0,0,0,2.163,38.957A19.535,19.535,0,0,0,21.63,26.075ZM19.467,60.707A15.213,15.213,0,1,1,34.752,45.422,15.231,15.231,0,0,1,19.467,60.707Z"
                    transform="translate(47.562 0) rotate(47)"
                    fill="#516371"
                />
            </g>
        </svg>
    );
}
