import React from 'react';

export default function ButtonNext(props) {
    const handleNext = () => {
        if (props.handleNext) {
            props.handleNext();
        }
    };
    return (
        <button
            className="btn btn-default"
            disabled={props.disabled}
            onClick={handleNext}
        >
            {props.text ? props.text : 'המשך לשלב הבא'}
        </button>
    );
}
