import React from 'react';

export default function Moon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.501"
            height="19.501"
            viewBox="0 0 19.501 19.501"
        >
            <path
                d="M22.557,14.311A9.019,9.019,0,1,1,12.746,4.5a7.015,7.015,0,0,0,9.811,9.811Z"
                transform="translate(-3.807 -3.75)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
