import React, { useState, Fragment } from 'react';
import MailDoodle from '../../components/MailDoodle';
import { setParticipant, setParticipantMeta, sendEmail } from '../../helpers';
import './ExitPopup.css';
export default function ExitPopup(props) {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [deliveryStatus, setDeliveryStatus] = useState('initial');

    const onSendMail = async () => {
        setDeliveryStatus('sending');
        const uuid = props.stateData.uuid;
        if (email === '') {
            setErrMsg('יש להזין כתובת דוא״ל');
            setDeliveryStatus('error');
            return;
        }
        if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
            )
        ) {
            setErrMsg('יש להזין כתובת דוא״ל תקינה');
            setDeliveryStatus('error');
            return;
        }

        const user = await setParticipant(uuid, email);

        if (user.error) {
            setErrMsg(user.message);
            setDeliveryStatus('error');
            return;
        }

        const nextMaleAudioIndex = await setParticipantMeta(
            uuid,
            'nextMaleAudioIndex',
            props.stateData.nextMaleAudioIndex
        );
        const nextMaleAudioIndexErr = await nextMaleAudioIndex.error;
        const nextFemaleAudioIndex = await setParticipantMeta(
            uuid,
            'nextFemaleAudioIndex',
            props.stateData.nextFemaleAudioIndex
        );
        const nextFemaleAudioIndexErr = await nextFemaleAudioIndex.error;
        const answers = await setParticipantMeta(
            uuid,
            'answers',
            props.stateData.answers
        );
        const answersErr = await answers.error;
        const audio = await setParticipantMeta(
            uuid,
            'sounds',
            props.stateData.sounds
        );
        const audioErr = await audio.error;
        const currentScreen = await setParticipantMeta(
            uuid,
            'currentScreen',
            props.stateData.currentScreen
        );
        const currentScreenErr = await currentScreen.error;

        if (
            !currentScreenErr &&
            !audioErr &&
            !answersErr &&
            !nextFemaleAudioIndexErr &&
            !nextMaleAudioIndexErr
        ) {
            const mailSent = await sendEmail(uuid);
            const hasError = await mailSent.error;
            if (hasError) {
                setDeliveryStatus('error');
                setErrMsg(mailSent.message);
                return;
            }
            setEmailSent(true);
        } else {
            const err = [];
            if (currentScreenErr) {
                err.push(currentScreen.message);
            }
            if (audioErr) {
                err.push(audio.message);
            }
            if (answersErr) {
                err.push(answers.message);
            }
            if (nextMaleAudioIndexErr) {
                err.push(nextMaleAudioIndex.message);
            }
            if (nextFemaleAudioIndexErr) {
                err.push(nextFemaleAudioIndex.message);
            }
            setDeliveryStatus('error');
            setErrMsg(err.join(' | '));
        }
    };
    return (
        <div className="exit">
            {!emailSent ? (
                <Fragment>
                    <h1 className="screen-title">
                        נשמח אם תשלימו את השאלון במועד מאוחר יותר
                    </h1>
                    <p style={{ textAlign: 'center' }}>
                        אנא הזינו כתובת מייל, אליה נוכל לשלוח קישור להמשך השאלון
                        במועד מאוחר יותר.{' '}
                    </p>

                    <form>
                        <input
                            type="text"
                            className="email"
                            name="email"
                            placeholder="האימייל שלך"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        {errMsg && <p className="err-msg">{errMsg}</p>}
                        <button
                            className="btn btn-default sendmail"
                            onClick={(e) => {
                                e.preventDefault();
                                onSendMail();
                            }}
                            disabled={
                                deliveryStatus === 'sending' ? true : false
                            }
                        >
                            <span>שליחה</span>
                            {deliveryStatus === 'sending' && (
                                <span className="spinner"></span>
                            )}
                        </button>
                    </form>
                </Fragment>
            ) : (
                <Fragment>
                    <MailDoodle />
                    <h1 className="screen-title">
                        נשלח קישור לכתובת המייל שציינת הכולל קישור חזרה להמשך
                        מילוי השאלון. נשמח אם תוכל להקדיש בעתיד מזמנך להשלמת
                        השאלון.
                    </h1>
                </Fragment>
            )}
        </div>
    );
}
