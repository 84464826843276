import React, { useState, useEffect } from 'react';
import { setParticipantMeta, setParticipant } from '../../helpers';
import './style.css';

export default function End(props) {
    const [submitted, setSubmitted] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('initial');

    const restartQuiz = () => {
        document.cookie =
            'PHPSESSID=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.location.reload();
    };
    useEffect(() => {
        async function insertDataToDb() {
            const data = props.finalState;
            const uuid = data.uuid;
            const answers = data.answers;
            // const audio = data.audio;

            // const user = await setParticipant(uuid, null);

            // if (user.error) {
            //     setErrMsg(user.message);
            //     // setDeliveryStatus('error');
            //     return;
            // }

            const sAnswers = await setParticipantMeta(uuid, 'answers', answers);
            const sAnswersErr = await sAnswers.error;

            // const sAudio = await setParticipantMeta(uuid, 'audio', audio);
            // const sAudioErr = await sAudio.error;

            const sSuccessEnd = await setParticipantMeta(uuid, 'successEnd', 1);
            let now = new Date(Date.now());
            const sSuccessEndErr = await sSuccessEnd.error;
            await setParticipantMeta(
                uuid,
                'endDateTime',
                now.toLocaleString('en-GB', { timeZone: 'Asia/Jerusalem' })
            );

            if (sAnswersErr || sSuccessEndErr) {
                setErrMsg(
                    'משהו השתבש במהלך עיבוד הנתונים. נא עדכנו אותנו על התקלה. תודה.'
                );
                return;
            }
            setSubmitted(true);
        }
        insertDataToDb();
    }, [props.finalState]);

    const onSendMail = async () => {
        setDeliveryStatus('sending');
        const uuid = props.finalState.uuid;
        if (email === '') {
            setErrMsg('יש להזין כתובת דוא״ל');
            setDeliveryStatus('error');
            return;
        }
        if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
            )
        ) {
            setErrMsg('יש להזין כתובת דוא״ל תקינה');
            setDeliveryStatus('error');
            return;
        }

        // const emailSent = await setParticipantMeta(uuid, 'email', email);
        const emailSent = await setParticipant(uuid, email);

        if (!emailSent) {
            setDeliveryStatus('error');
            setErrMsg('משהו השתבש. נא נסו שנית.');
        } else {
            setDeliveryStatus('sent');
            setEmailSent(true);
        }
    };

    return (
        <React.Fragment>
            <main className="main screen screen-end">
                {submitted ? (
                    <React.Fragment>
                        <h1>זהו, נגמר!</h1>
                        <h2 style={{ textAlign: 'center' }}>
                            אנו מודים לך מאוד על השתתפותך בשאלון ומזמינים אותך
                            להמשיך לעקוב אחר פתרונות הנגישות של Bettear באתרינו
                            וברשתות החברתיות.{' '}
                            <a href="https://bettear.com">Bettear.com</a>
                        </h2>
                        <p style={{ textAlign: 'center' }}>
                            רוצה שנמשיך לעדכן אותך על פיתוחים טכנולוגים בתחום
                            השמיעה בהמשך?
                        </p>
                        <form>
                            <input
                                type="text"
                                className="email"
                                name="email"
                                placeholder="כתובת מייל לעדכונים"
                                aria-label="הזן כתובת מייל לקבלת עדכונים"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                            {errMsg && <p className="err-msg">{errMsg}</p>}
                            <button
                                className="btn btn-default sendmail"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSendMail();
                                }}
                                disabled={
                                    deliveryStatus === 'sending' ? true : false
                                }
                            >
                                <span>שליחה</span>
                                {deliveryStatus === 'sending' && (
                                    <span className="spinner"></span>
                                )}
                            </button>
                        </form>
                        {emailSent && (
                            <p style={{ textAlign: 'center' }}>
                                כתובת המייל נשלחה בהצלחה. תודה.
                            </p>
                        )}

                        <button
                            onClick={restartQuiz}
                            style={{
                                width: '100%',
                                maxWidth: '450px',
                                marginTop: '45px',
                            }}
                        >
                            החל ניסוי חדש
                        </button>
                    </React.Fragment>
                ) : (
                    <>
                        {errMsg ? (
                            <h1>{errMsg}</h1>
                        ) : (
                            <>
                                <h1>
                                    אנו מעבדים את הפרטים. נא לא לצאת מעמוד זה עד
                                    להודעת תודה.
                                </h1>
                                <div className="loader-container">
                                    <div className="lds-roller">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </main>
        </React.Fragment>
    );
}
