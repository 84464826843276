import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Howl } from 'howler';
import { dbToLinear, findAvarage, linearToDb, scrollTop } from '../../helpers';
import { useInterval } from '../../hooks/useInterval';

import GenderDoodle from '../../components/GenderDoodle';
import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
// import SkipNotice from '../../components/SkipNotice';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
import FooterActions from '../../components/FooterActions';
import AudioDescription from '../../components/AudioDescription';

export default function AudioSNRTS(props) {
    const debug = props.debug;
    const maxPlaybackRate = 1.5;
    const minPlaybackRate = 0.5;
    const step = 0.05;
    const gender = useRef();
    const speaker = gender.current === 'male' ? 'דובר' : 'דוברת';
    const [voiceOverPlayer, setVoiceOverPlayer] = useState(null);
    const [noisePlayer, setNoisePlayer] = useState(null);
    const [playerState, setPlayerState] = useState('paused');
    const [voiceOverFile, setVoiceOverFile] = useState(null);
    const [noiseFile, setNoiseFile] = useState(null);
    const [voiceOverLoaded, setVoiceOverLoaded] = useState(false);
    const [noiseLoaded, setNoiseLoaded] = useState(false);

    const initialWpm = useRef();
    const initialPPS = useRef();
    const initialDB = useRef();
    const initialSNR = useRef();
    const voiceOverVol = useRef(null);
    const noiseVol = useRef(null);
    const [
        shouldIncreaseVoiceOverIndex,
        setShouldIncreaseVoiceOverIndex,
    ] = useState(false);

    const [canGoNext, setCanGoNext] = useState(false);
    const voiceOverIndex = useRef();

    const [playbackRate, setPlaybackRate] = useState(1);
    let timeout = useRef();
    let noiseType = useRef();
    let noiseFileRef = useRef(null);
    let voiceOverFileRef = useRef();
    const audioList = props.audio;
    const initialProps = props;
    const [maleAvgPbRate, setMaleAvgPbRate] = useState([]);
    const [femaleAvgPbRate, setFemaleAvgPbRate] = useState([]);
    const [maleAvgPPS, setMaleAvgPPS] = useState([]);
    const [femaleAvgPPS, setfemaleAvgPPS] = useState([]);
    const [pbrTiming, setPbrTiming] = useState([]);

    const handleSkip = () => {
        handleNext(true);
    };

    const handleNext = async (skipped = false) => {
        clearTimeout(timeout.current);
        if (noisePlayer) {
            noisePlayer.unload();
        }
        if (voiceOverPlayer) {
            voiceOverPlayer.unload();
        }
        setVoiceOverLoaded(false);
        setCanGoNext(false);
        const currentGender = gender.current;
        const isMale = currentGender === 'male';
        let Nlabel = isMale ? `N'm(${noiseType.current})` : `N'f(${noiseType.current})`;

        const WPMlabel = isMale
                ? `mWPM${noiseType.current}`
                : `fWPM${noiseType.current}`;
        
        const calcWPM = Number(initialWpm.current * playbackRate).toFixed(2);
        const wpmValue = skipped ? null : Number(calcWPM);

        const ppsLabel = isMale
            ? `mPPS${noiseType.current}`
            : `fPPS${noiseType.current}`

        const ppsValue = skipped ? null : Number(Number(initialPPS.current * playbackRate).toFixed(1))

        await props.insertMultiAnswers({
            [Nlabel]: skipped
                ? null
                : Number(Number(linearToDb(noiseVol.current)).toFixed(2)),
            [WPMlabel]: skipped ? null : Number(calcWPM),
            [`${currentGender}WPM${noiseType.current}(t)`]: pbrTiming,
            [ppsLabel]: ppsValue,
            [`${currentGender}SNRTS${noiseType.current}File`]: voiceOverFileRef.current.split('\\').pop().split('/').pop(),
        });

        setPbrTiming([]);
        
        if (isMale) {
            if (wpmValue === null) {
                setMaleAvgPbRate([...maleAvgPbRate, null]);
            } else {
                setMaleAvgPbRate([...maleAvgPbRate, wpmValue]);
            }

            setMaleAvgPPS([...maleAvgPPS, ppsValue])
        } else {
            if (wpmValue === null) {
                setFemaleAvgPbRate([...femaleAvgPbRate, null]);
            } else {
                setFemaleAvgPbRate([...femaleAvgPbRate, wpmValue]);
            }

            setfemaleAvgPPS([...femaleAvgPPS, ppsValue])
        }

        if (props.content.last) {
            const finalAvgMale = maleAvgPbRate;
            const finalAvFemale = femaleAvgPbRate;

            const finalAvgMalePPS = maleAvgPPS
            const finalAvgfemalePPS = femaleAvgPPS
            if (isMale) {
                if (wpmValue === null) {
                    finalAvgMale.push(null);
                } else {
                    finalAvgMale.push(wpmValue);
                }
                finalAvgMalePPS.push(ppsValue)

            } else {
                if (wpmValue === null) {
                    finalAvFemale.push(null);
                } else {
                    finalAvFemale.push(wpmValue);
                }
                finalAvgfemalePPS.push(ppsValue)
            }
            await props.insertMultiAnswers({
                mAvgWPMwNoise: Number(
                    Number(findAvarage(finalAvgMale)).toFixed(2)
                ),
                fAvgWPMwNoise: Number(
                    Number(findAvarage(finalAvFemale)).toFixed(2)
                ),
                mAvgPPSwNoise: Number(Number(findAvarage(finalAvgMalePPS)).toFixed(1)),
                fAvgPPSwNoise: Number(Number(findAvarage(finalAvgfemalePPS)).toFixed(1)),
            });
        }
        if (shouldIncreaseVoiceOverIndex) {
            await initialProps.increaseGenderIndex(currentGender);
        }

        initialProps.actionNext();
    };

    const increaseVoiceOverIndex = useCallback(() => {
        initialProps.increaseGenderIndex(gender.current);
        voiceOverIndex.current++;
        setVoiceOverFile(
            audioList[gender.current][voiceOverIndex.current].location
        );
        setShouldIncreaseVoiceOverIndex(false);
        setVoiceOverLoaded(false);
    }, [initialProps, audioList]);

    useInterval(() => {
        if (voiceOverPlayer.playing() && voiceOverFileRef.current !== null) {
            const tmp = pbrTiming;
            const index = tmp.findIndex(
                (p) => p.file === voiceOverFileRef.current
            );

            if (index === -1) {
                tmp.push({ file: voiceOverFileRef.current, timing: [] });
                tmp[tmp.length - 1].timing = [
                    {
                        Ts: voiceOverPlayer.seek(),
                        wpm: initialWpm.current * playbackRate,
                    },
                ];
            } else {
                tmp[index].timing = [
                    ...tmp[index].timing,
                    {
                        Ts: voiceOverPlayer.seek(),
                        wpm: initialWpm.current * playbackRate,
                    },
                ];
            }
            setPbrTiming(tmp);
        }
    }, 200);

    useEffect(() => {
        if (gender.current !== props.content.gender) {
            scrollTop();
            setPlaybackRate(1);

            const nextGender = props.content.gender;
            const isMale = nextGender === 'male';
            const nextVoiceOverIndex = isMale ? props.maleIndex : props.femaleIndex;
            const nextVoiceOverFile = audioList[nextGender][nextVoiceOverIndex]
            gender.current = nextGender;
            voiceOverIndex.current = nextVoiceOverIndex;
            if (
                voiceOverFileRef.current !==
                nextVoiceOverFile.location
            ) {
                setVoiceOverFile(nextVoiceOverFile.location);
                setVoiceOverLoaded(false);
                voiceOverFileRef.current =
                nextVoiceOverFile.location;

                initialWpm.current =
                nextVoiceOverFile.wpm;
                initialPPS.current = nextVoiceOverFile.pps;
            }
            initialDB.current = isMale ? props.sm : props.sf;
            initialSNR.current = isMale ? props.avgSNRm : props.avgSNRf;

            noiseVol.current = dbToLinear(
                initialDB.current - initialSNR.current
            );
            voiceOverVol.current = dbToLinear(initialDB.current);

            noiseType.current = props.content.audio;
            if (noiseFile !== audioList.noises[noiseType.current][0].location) {
                setNoiseFile(audioList.noises[noiseType.current][0].location);
                setNoiseLoaded(false);
                noiseFileRef.current =
                    audioList.noises[noiseType.current][0].location;
            }
        }
        return function cleanup() {
            setVoiceOverFile(null);
            setNoiseFile(null);
        };
    }, [
        props.content.gender,
        props.content.audio,
        noiseFile,
        audioList,
        props.maleIndex,
        props.femaleIndex,
        props.sm,
        props.sf,
        voiceOverFile,
        props.avgSNRf,
        props.avgSNRm,
    ]);

    useEffect(() => {
        if (
            voiceOverPlayer &&
            voiceOverPlayer.state &&
            voiceOverPlayer.state('loaded')
        ) {
            voiceOverPlayer.rate(playbackRate);
            
            if (!debug) return;

            console.log(
                `Playback Rate: ${playbackRate},
                Initial WPM: ${
                    initialWpm.current
                }, Current WPM: ${
                    initialWpm.current * playbackRate
                }, Initial PPS : ${initialPPS.current}, Current PPS: ${initialPPS.current * playbackRate}`
            );
        }
    }, [playbackRate, voiceOverPlayer, debug]);

    useEffect(() => {
        if (voiceOverFile !== null && voiceOverVol.current !== null) {
            setVoiceOverPlayer(
                new Howl({
                    src: voiceOverFile,
                    volume: voiceOverVol.current,
                    html5: true,
                    onload: () => {
                        setVoiceOverLoaded(true);

                        if (!debug) return;

                        console.log(
                            `Voice Over Player Loaded. Current File: ${voiceOverFile}. Volume: ${voiceOverVol.current}`
                        );
                    },
                    onplay: () => {
                        setShouldIncreaseVoiceOverIndex(true);
                        setCanGoNext(true);
                    },

                    onend: () => {
                        increaseVoiceOverIndex();
                    },
                })
            );
        }
    }, [voiceOverFile, increaseVoiceOverIndex, debug]);

    useEffect(() => {
        if (noiseFile !== null && noiseVol.current !== null) {
            setNoisePlayer(
                new Howl({
                    src: noiseFile,
                    volume: noiseVol.current,
                    onload: () => {
                        setNoiseLoaded(true);

                        if (!debug) return;

                        console.log(
                            `Noise Player Loaded. Current File: ${noiseFile}. Volume: ${noiseVol.current}`
                        );
                    },
                    onplay: () => {
                        setPlayerState('playing');
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                    },
                })
            );
        }
    }, [noiseFile, debug]);

    useEffect(() => {
        if (playerState === 'playing') {
            if (voiceOverPlayer) {
                timeout.current = setTimeout(() => {
                    voiceOverPlayer.play();
                }, 3000);
            }
        } else {
            if (voiceOverPlayer) {
                voiceOverPlayer.pause();
            }
            clearTimeout(timeout.current);
        }
    }, [playerState, voiceOverPlayer]);

    return (
        <React.Fragment>
            <main className="main screen">
                <GenderDoodle gender={gender.current} />
                <h1 className="screen-title">
                    קצב דיבור מרבי להבנה על רקע רעש
                </h1>
                <p style={{ textAlign: 'center' }}>
                    בהישמע קטע הקול, יש להזיז את הנקודה הכחולה ימינה ושמאלה
                    בהתאם,{' '}
                    <strong>
                        כך שקצב הדיבור יהיה מרבי, אך מבלי לפגוע ביכולת להבין את
                        הדיבור
                    </strong>
                    .
                </p>
                <AudioDescription
                    speaker={gender.current === 'male' ? 'דובר' : 'דוברת'}
                />
                <PlayerControllers
                    playerState={playerState}
                    audioLoaded={voiceOverLoaded && noiseLoaded ? true : false}
                    play={() => {
                        if (noisePlayer) {
                            noisePlayer.play();
                        }
                    }}
                    pause={() => {
                        if (noisePlayer) {
                            noisePlayer.pause();
                        }
                    }}
                />
                <Slider
                    min={minPlaybackRate}
                    max={maxPlaybackRate}
                    step={step}
                    labelIncrease="הגבר את קצב הדיבור"
                    labelDecrease="הנמך את קצב הדיבור"
                    playerState={playerState}
                    value={playbackRate}
                    sliderAction={(value) => {
                        setPlaybackRate(value);
                    }}
                    increaseAction={() => {
                        setPlaybackRate(
                            Number(playbackRate) + step >= maxPlaybackRate
                                ? maxPlaybackRate
                                : Number(playbackRate) + step
                        );
                    }}
                    decreaseAction={() => {
                        setPlaybackRate(
                            Number(playbackRate) - step <= minPlaybackRate
                                ? minPlaybackRate
                                : Number(playbackRate) - step
                        );
                    }}
                />
                {/* <SkipNotice /> */}
                <DoNotChangeNotice />
            </main>

            <FooterActions
                actionNext={handleNext}
                canSkip="true"
                skipDisabled={canGoNext === true ? false : true}
                nextDisabled={canGoNext === true ? false : true}
                actionSkip={handleSkip}
            />
        </React.Fragment>
    );
}
