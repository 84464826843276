import React, { Fragment, useRef, useEffect, useState } from 'react';
import {
    scrollTop,
    dbToLinear,
    setMaxDbVolume,
    setMinDbVolume,
    numberToFixed,
} from '../../helpers';
import { Howl } from 'howler';
import { useInterval } from '../../hooks/useInterval';

import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
import FooterActions from '../../components/FooterActions';
import AudioDescription from '../../components/AudioDescription';
// import SkipNotice from '../../components/SkipNotice';

export default function Band(props) {
    const debug = props.debug;
    const minDbVal = useRef();
    const maxDbVal = useRef();
    const step = 1;
    const gender = useRef();
    const singer = useRef();
    const singerText = gender.current === 'male' ? 'זמר' : 'זמרת';
    const [vocalPlayer, setVocalPlayer] = useState(null);
    const [instPlayer, setInstPlayer] = useState(null);
    const [vocalFile, setVocalFile] = useState(null);
    const [instFile, setInstFile] = useState(null);
    const initialDB = useRef();
    const vocalDB = useRef(null);

    const [instDb, setInstDb] = useState(0);
    const instFileRef = useRef();
    const [vocalLoaded, setVocalLoaded] = useState(false);
    const [instLoaded, setInstLoaded] = useState(false);
    const [playerState, setPlayerState] = useState('paused');
    const audioList = props.audio;
    const [canGoNext, setCanGoNext] = useState(false);

    const [pbrTiming, setPbrTiming] = useState([]);
    const [qCurrent, setQCurrent] = useState(1);

    const handleSkip = () => {
        handleNext(true);
    };

    const handleNext = async (skipped = false) => {
        setCanGoNext(false);
        setPlayerState('paused');
        if (instPlayer) {
            instPlayer.stop();
        }
        if (vocalPlayer) {
            vocalPlayer.stop();
        }
        setVocalLoaded(false);

        const snrSong = numberToFixed(vocalDB.current - instDb);

        await props.insertMultiAnswers({
            [`Lsinger${qCurrent}`]: skipped
                ? null
                : numberToFixed(dbToLinear(vocalDB.current)),
            [`Nsinger${qCurrent}`]: skipped
                ? null
                : numberToFixed(vocalDB.current),
            [`Linstr${qCurrent}`]: skipped
                ? null
                : numberToFixed(dbToLinear(instDb)),
            [`Ninstr${qCurrent}`]: skipped ? null : numberToFixed(instDb),
            [`SNRsong${qCurrent}`]: skipped ? null : snrSong,
            [`Ninstr${qCurrent}(t)`]: pbrTiming,
        });
        setPbrTiming([]);

        setQCurrent((q) => q + 1);
        props.actionNext();
    };

    useInterval(() => {
        if (playerState !== 'paused') {
            const tmp = pbrTiming;
            const index = tmp.findIndex((p) => p.file === instFileRef);

            if (index === -1) {
                tmp.push({ file: instFileRef, timing: [] });
                tmp[tmp.length - 1].timing = [
                    {
                        'T(t)': instPlayer.seek(),
                        'Ninstr(t)': instDb,
                    },
                ];
            } else {
                tmp[index].timing = [
                    ...tmp[index].timing,
                    {
                        'T(t)': instPlayer.seek(),
                        'Ninstr(t)': instDb,
                    },
                ];
            }
            setPbrTiming(tmp);
        }
    }, 200);

    useEffect(() => {
        if (gender.current !== props.content.gender) {
            gender.current = props.content.gender;
            initialDB.current =
                props.content.gender === 'male' ? props.sm : props.sf;
            vocalDB.current = setMaxDbVolume(initialDB.current, 2);

            setInstDb(initialDB.current);

            minDbVal.current = setMinDbVolume(initialDB.current, 50);
            maxDbVal.current = setMaxDbVolume(initialDB.current, 20);
        }
        if (singer.current !== props.content.singer) {
            scrollTop();
            singer.current = props.content.singer;
            setVocalFile(audioList[singer.current].vocal.location);
            setInstFile(audioList[singer.current].music.location);
            setVocalLoaded(false);
            setInstLoaded(true);
            instFileRef.current = audioList[singer.current].music.location;
        }
        return function cleanup() {
            setVocalFile(null);
            setInstFile(null);
        };
    }, [
        props.content.gender,
        props.content.singer,
        audioList,
        props.sm,
        props.sf,
    ]);

    useEffect(() => {
        if (vocalFile !== null && vocalDB.current !== null) {
            setVocalPlayer(
                new Howl({
                    src: vocalFile,
                    volume: dbToLinear(vocalDB.current),
                    onload: () => {
                        setVocalLoaded(true);
                        if (!debug) {
                            return;
                        }
                        console.log(
                            `Vocal player loaded. Current file: ${vocalFile}, volume: ${dbToLinear(
                                vocalDB.current
                            )}, dB: ${vocalDB.current}, initial dB: ${
                                initialDB.current
                            }`
                        );
                    },
                })
            );
        }
    }, [vocalFile, debug]);

    useEffect(() => {
        if (instFile !== null && initialDB.current !== null) {
            setInstPlayer(
                new Howl({
                    src: instFile,
                    onload: () => {
                        setInstLoaded(true);
                        if (!debug) {
                            return;
                        }
                        console.log(
                            `instrument player loaded. Current file: ${instFile}, dB: ${
                                initialDB.current
                            }, Volume: ${dbToLinear(initialDB.current)}`
                        );
                    },
                    onplay: () => {
                        setPlayerState('playing');
                        setCanGoNext(true);
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                    },
                })
            );
        }
    }, [instFile, debug]);

    useEffect(() => {
        if (instPlayer !== null) {
            instPlayer.volume(dbToLinear(instDb));
            if (!debug) {
                return;
            }
            console.log(
                `Instr Player - Min: ${minDbVal.current}, Max: ${
                    maxDbVal.current
                }, dD: ${instDb}, SNR: ${
                    vocalDB.current - instDb
                }, Linear: ${dbToLinear(instDb)}`
            );
        }
    }, [instDb, instPlayer, debug]);

    return (
        <Fragment>
            <main className="main screen">
                <h1>העדפה מוסיקלית – יחס זמר/להקה</h1>

                <p style={{ textAlign: 'center' }}>
                    בהישמע הקטע המוסיקלי, יש להזיז הנקודה הכחולה ימינה ושמאלה,
                    בהתאם,{' '}
                    <strong>
                        כך שיחס עוצמת הקול בין ה{singerText} למנגינה יהיה
                        לשביעות רצונך
                    </strong>
                    .
                </p>
                <AudioDescription />
                <PlayerControllers
                    play={() => {
                        if (instPlayer !== null) {
                            instPlayer.play();
                        }
                        if (vocalPlayer !== null) {
                            vocalPlayer.play();
                        }
                    }}
                    pause={() => {
                        if (instPlayer !== null) {
                            instPlayer.pause();
                        }
                        if (vocalPlayer !== null) {
                            vocalPlayer.pause();
                        }
                    }}
                    audioLoaded={instLoaded && vocalLoaded ? true : false}
                    playerState={playerState}
                />
                <Slider
                    max={maxDbVal.current}
                    min={minDbVal.current}
                    step={step}
                    value={instDb}
                    playerState={playerState}
                    sliderAction={(value) => {
                        setInstDb(Number(value));
                        // setInstVolume(dbToLinear(value));
                    }}
                    increaseAction={() => {
                        const tmp =
                            maxDbVal.current <= Number(instDb) + step
                                ? maxDbVal.current
                                : Number(instDb) + step;
                        setInstDb(Number(tmp));
                        // setInstVolume(dbToLinear(tmp));
                    }}
                    decreaseAction={() => {
                        const tmp =
                            minDbVal.current >= Number(instDb) - step
                                ? minDbVal.current
                                : Number(instDb) - step;
                        setInstDb(Number(tmp));
                        // setInstVolume(dbToLinear(tmp));
                    }}
                />
                {/* <SkipNotice /> */}
                <DoNotChangeNotice />
            </main>
            <FooterActions
                actionNext={handleNext}
                canSkip="true"
                skipDisabled={canGoNext === true ? false : true}
                nextDisabled={canGoNext === true ? false : true}
                actionSkip={handleSkip}
            />
        </Fragment>
    );
}
