import React from 'react';

export default function A11yToggler() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="40"
            viewBox="0 0 30 40"
        >
            <g transform="translate(0 -122)">
                <path
                    d="M15,0H25a20,20,0,0,1,0,40H15Z"
                    transform="translate(-15 122)"
                    fill="#0c0b26"
                />
                <path
                    d="M14.5,3a2.222,2.222,0,1,1-2.222,2.222A2.229,2.229,0,0,1,14.5,3Zm10,7.778H17.833V25.222H15.611V18.556H13.389v6.667H11.167V10.778H4.5V8.556h20Z"
                    transform="translate(-1.5 128.889)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
