import React from 'react';

export default function Play() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
        >
            <path
                d="M30.563.563a30,30,0,1,0,30,30A29.995,29.995,0,0,0,30.563.563Zm14,32.9L23.268,45.683a2.908,2.908,0,0,1-4.319-2.54V17.982a2.91,2.91,0,0,1,4.319-2.54l21.29,12.944A2.913,2.913,0,0,1,44.558,33.466Z"
                transform="translate(-0.563 -0.563)"
            />
        </svg>
    );
}
