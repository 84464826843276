import React, { useState, useEffect, useRef } from 'react';
import {
    scrollTop,
    dbToLinear,
    // linearToDb,
    setMaxDbVolume,
    setMinDbVolume,
    setCookies,
} from '../../helpers';
import { useInterval } from '../../hooks/useInterval';
import { Howl } from 'howler';
import PlayerControllers from '../../components/PlayerControllers';
import FooterActions from '../../components/FooterActions';
import Slider from '../../components/Slider';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
// import SkipNotice from '../../components/SkipNotice';
import AudioDescription from '../../components/AudioDescription';

export default function NoiseEffect(props) {
    const debug = props.debug;
    const step = 1;
    const [qIndex, SetQIndex] = useState(null);

    const [effectPlayer, setEffectPlayer] = useState(null);
    const [noisePlayer, setNoisePlayer] = useState(null);
    const [noiseLoaded, setNoiseLoaded] = useState(false);
    const [effectLoaded, setEffectLoaded] = useState(false);
    const [playerState, setPlayerState] = useState('paused');
    const { nsp } = props;
    const maxVol = setMaxDbVolume(nsp, 20);
    const minVol = setMinDbVolume(nsp, 50);
    // const [effectVol] = useState(dbToLinear(nsp));
    const [effectDb, setEffectDb] = useState(nsp - 2);
    const [pbrTiming, setPbrTiming] = useState([]);
    const effectLabel = useRef();
    const effectText = useRef();
    const [canGoNext, setCanGoNext] = useState(false);
    const audioList = props.audio;
    // const audioListRef = useRef(null);
    // const [audioList, setAudioListS] = useState(null);

    const handleSkip = async () => {
        await handleNext(null);
    };
    const handleNext = async (isNull = false) => {
        effectPlayer.unload();
        noisePlayer.unload();
        setCanGoNext(false);
        setNoiseLoaded(false);
        await props.insertMultiAnswers({
            [`L${effectLabel.current}`]: isNull ? null : dbToLinear(effectDb),
            [`S${effectLabel.current}`]: isNull ? null : effectDb,
            [`SNR${effectLabel.current}`]: isNull ? null : nsp - effectDb,
            [`S${effectLabel.current}(t)`]: isNull ? null : pbrTiming,
        });

        setPbrTiming([]);

        setNoisePlayer(null);
        setEffectPlayer(null);
        if (qIndex < 2) {
            SetQIndex((i) => i + 1);
            await setCookies({ noiseEffectIndex: qIndex + 1 });
        }
        props.actionNext();
    };

    useInterval(() => {
        if (effectPlayer && effectPlayer.playing()) {
            setPbrTiming((current) => [
                ...current,
                { 'T(t)': effectPlayer.seek(), 'S(t)': dbToLinear(effectDb) },
            ]);
        }
    }, 200);

    useEffect(() => {
        if (effectPlayer) {
            effectPlayer.volume(dbToLinear(effectDb));
            if (!debug) {
                return;
            }
            console.log(
                `Effect Volume: [dB: ${effectDb}, linear: ${dbToLinear(
                    effectDb
                )}], Noise: [dB: ${nsp}, linear: ${dbToLinear(nsp)}], SNR: ${
                    nsp - effectDb
                }`
            );
        }
    }, [effectDb, effectPlayer, nsp, debug]);

    useEffect(() => {
        scrollTop();
        if (qIndex !== null) {
            effectLabel.current = audioList[qIndex][1].effect.label;
            switch (effectLabel.current) {
                case 'leaves':
                    effectText.current = 'העלים';
                    break;
                case 'wood':
                    effectText.current = 'חריקות העץ';
                    break;
                case 'water':
                    effectText.current = 'טיפות המים';
                    break;
                case 'knock':
                    effectText.current = 'הדפיקות';
                    break;
                default:
                    break;
            }
            setEffectDb(nsp - 2);
            setEffectPlayer(
                new Howl({
                    src: audioList[qIndex][1].effect.location,
                    onload: () => {
                        setEffectLoaded(true);
                        if (!debug) {
                            return;
                        }
                        console.log(
                            `Effect Loaded. File: ${audioList[qIndex][1].effect.location}`
                        );
                    },
                })
            );
        }
        return function cleanup() {};
    }, [audioList, qIndex, nsp, debug]);

    useEffect(() => {
        if (qIndex !== null) {
            setNoisePlayer(
                new Howl({
                    src: audioList[qIndex][1].noise.location,
                    volume: dbToLinear(nsp),
                    onload: () => {
                        setNoiseLoaded(true);
                        
                        if (!debug) {
                            return;
                        }
                        console.log(
                            `Noise Loaded. File: ${audioList[qIndex][1].noise.location}`
                        );
                    },
                    onplay: () => {
                        setPlayerState('playing');
                        setCanGoNext(true);
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                })
            );
        }
        return function cleanup() {};
    }, [audioList, qIndex, nsp, debug]);

    useEffect(async () => {
        const getCookies = async (values) => {
            let queryString = null;
            if (values) {
                queryString = Object.keys(values)
                    .map((key) => {
                        return (
                            encodeURIComponent(key) +
                            '=' +
                            encodeURIComponent(values[key])
                        );
                    })
                    .join('&');
            }
            const reqUrl = queryString
                ? `${process.env.REACT_APP_API_URI}/cookies?${queryString}`
                : `${process.env.REACT_APP_API_URI}/cookies`;
            const response = await fetch(reqUrl, {
                method: 'GET',
                headers: {
                    'X-API-Key': process.env.REACT_APP_API_KEY,
                },
            });

            const output = await response.json();
            return output;
        };
        let loaded = true;
        if (loaded) {
            scrollTop();
            const cookies = await getCookies();

            const currentIndex = await cookies.data.noiseEffectIndex;

            SetQIndex(currentIndex ? currentIndex : 0);
        }
        return function cleanup() {
            loaded = false;
        };
    }, []);
    return (
        <React.Fragment>
            <main className="main screen">
                <h1 className="screen-title">זיהוי אפקט קולי על רקע רעש</h1>
                <p style={{ textAlign: 'center' }}>
                    בעת הישמע קטע הקול, יש להזיז את הנקודה הכחולה ימינה ושמאלה,
                    בהתאם, כך שניתן יהיה{' '}
                    <strong>
                        להבחין בבירור בצלילי/רעשי הרקע, אך יהיה ברור כי מוקד
                        הקול הינו <u>רעש {effectText.current}</u>
                    </strong>
                    .
                </p>
                <AudioDescription />
                <PlayerControllers
                    play={() => {
                        noisePlayer.play();
                        effectPlayer.play();
                    }}
                    pause={() => {
                        noisePlayer.pause();
                        effectPlayer.pause();
                    }}
                    audioLoaded={noiseLoaded && effectLoaded ? true : false}
                    playerState={playerState}
                />
                <Slider
                    min={minVol}
                    max={maxVol}
                    value={effectDb}
                    step={step}
                    playerState={playerState}
                    sliderAction={(e) => {
                        setEffectDb(Number(e));
                    }}
                    increaseAction={() => {
                        setEffectDb(
                            effectDb + step > maxVol ? maxVol : effectDb + step
                        );
                    }}
                    decreaseAction={() => {
                        setEffectDb(
                            effectDb - step < minVol ? minVol : effectDb - step
                        );
                    }}
                    labelIncrease="הגבר את עוצמת האפקט הקולי"
                    labelDecrease="הנמך את עוצמת האפקט הקולי"
                />
                {/* <SkipNotice /> */}
                <DoNotChangeNotice />
            </main>
            <FooterActions
                actionNext={handleNext}
                actionSkip={handleSkip}
                canSkip={true}
                skipDisabled={canGoNext === true ? false : true}
                nextDisabled={canGoNext === true ? false : true}
            />
        </React.Fragment>
    );
}
