import React from 'react';

export default function DoNotChangeNotice() {
    return (
        <p style={{ textAlign: 'center' }}>
            <b>
                יש להימנע משינויים במאפייני אביזר השמע ובעוצמת השמע של
                הסמארטפון/המחשב
            </b>
        </p>
    );
}
