import React from 'react';
import FemaleDoodle from '../icons/FemaleDoodle';
import MaleDoodle from '../icons/MaleDoodle';
import './style.css';
export default function GenderDoodle(props) {
    return (
        <React.Fragment>
            {props.gender && props.gender === 'male' ? (
                <div className="gender-doodle">
                    <MaleDoodle />
                </div>
            ) : (
                <div className="gender-doodle">
                    <FemaleDoodle />
                </div>
            )}
        </React.Fragment>
    );
}
