import React from 'react';

export default function FemaleDoodle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200.461"
            viewBox="0 0 200 200.461"
        >
            <g transform="translate(-107 -329)">
                <path
                    d="M748.226,2035.1c-19.132,13.044-28.7,20.349-61.569,23.132s-63.854-20.523-63.854-20.523Z"
                    transform="translate(-477 -1529)"
                    fill="#edeffd"
                />
                <g transform="translate(-621 -141)">
                    <g transform="translate(0 1)">
                        <path
                            d="M322.792,391c0,23.554-21.78,19.992-48.646,19.992S225.5,414.555,225.5,391,236.138,325.7,274.145,325.7C313.462,325.7,322.792,367.447,322.792,391Z"
                            transform="translate(554.042 187.153)"
                            fill="#0c0b26"
                        />
                        <circle
                            cx="32.734"
                            cy="32.734"
                            r="32.734"
                            transform="translate(795.29 532.636)"
                            fill="#ffb8b8"
                        />
                        <path
                            d="M233.5,371.347h12.947l5.712-15.993,1.142,15.993h6.188l3.332-9.329.666,9.329h45.981A34.652,34.652,0,0,0,274.815,336.7h-6.664A34.652,34.652,0,0,0,233.5,371.347Z"
                            transform="translate(556.704 190.813)"
                            fill="#0c0b26"
                        />
                    </g>
                    <path
                        d="M698.113,1983.52l5.458,17.214H673.008l4.366-17.214Z"
                        transform="translate(140.914 -1392.263)"
                        fill="#ffb8b8"
                    />
                    <path
                        d="M217.455-1189.08a99.2,99.2,0,0,1-34.652-17.46l10.787-25.375a12.069,12.069,0,0,1,5.319-6.452,21.332,21.332,0,0,1,13.3-6.942l60.322-7.638a21.267,21.267,0,0,1,16.993,5.291,12.088,12.088,0,0,1,5.278,6.451l13.622,32.036a99.268,99.268,0,0,1-37.878,20.089Z"
                        transform="translate(584.197 1855)"
                        fill="#edeffd"
                    />
                    <g
                        transform="translate(728 470)"
                        fill="none"
                        stroke="#e0e1e5"
                        strokeWidth="1"
                    >
                        <circle cx="100" cy="100" r="100" stroke="none" />
                        <circle cx="100" cy="100" r="99.5" fill="none" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
