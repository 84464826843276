import React from 'react';

export default function Earphones(props) {
    let primaryColor = props.selected === true ? '#4357e7' : '#516371';
    let secondaryColor =
        props.selected === true ? 'rgba(67,87,231,0.3)' : 'rgba(81,99,113,0.3)';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="38.958"
            viewBox="0 0 48 38.958"
        >
            <g
                id="Group_176"
                data-name="Group 176"
                transform="translate(-3111.019 -1871.419)"
            >
                <path
                    id="Path_460"
                    data-name="Path 460"
                    d="M72.794,356.431c-.33-1.3-5.863-1.348-7.166.177-1.49,1.746-2.634,11.913-1.88,17.289a5.7,5.7,0,0,0,.784,2.489,5.393,5.393,0,0,0,2.874,1.816,6.975,6.975,0,0,0,2.463.443,3.1,3.1,0,0,0,2.241-.979,3.955,3.955,0,0,0,.6-3.194C71.807,368.6,73.124,357.729,72.794,356.431Z"
                    transform="translate(3049.541 1531.729)"
                    fill={secondaryColor}
                />
                <path
                    id="Path_461"
                    data-name="Path 461"
                    d="M499.667,359.286c-.012-.06,5.644-.19,6.121-.174a1.365,1.365,0,0,1,1.538,1.311,39.1,39.1,0,0,1,1.2,16.159,5.7,5.7,0,0,1-.784,2.487,5.387,5.387,0,0,1-2.874,1.818,6.976,6.976,0,0,1-2.463.443,3.1,3.1,0,0,1-2.241-.981,3.959,3.959,0,0,1-.6-3.194C500.468,371.284,500.858,365.156,499.667,359.286Z"
                    transform="translate(2650.134 1528.565)"
                    fill={secondaryColor}
                />
                <g id="Group_174" data-name="Group 174">
                    <path
                        id="Path_462"
                        data-name="Path 462"
                        d="M58.663,337.433a1.481,1.481,0,0,0-.932-.21c-.546-.024-1.09-.04-1.635-.056-1.186-.033-2.373-.067-3.558-.077a2.772,2.772,0,0,0-1.766.391c-.848.641-1.035,1.959-1.276,2.92a41.684,41.684,0,0,0-1.15,7.2,39.4,39.4,0,0,0,.169,7.411,9.7,9.7,0,0,0,.765,3.216,4.674,4.674,0,0,0,2.117,2.033c1.763.906,4.229,1.576,6.039.448,1.924-1.2,1.794-3.581,1.51-5.532a48.984,48.984,0,0,1-.037-16.592c.088-.44.061-1.049-.417-1.268-.425-.2-.894.2-.977.616a46.643,46.643,0,0,0-.6,12.88q.133,1.638.345,3.271c.124.971.354,1.96.39,2.94a1.97,1.97,0,0,1-.9,1.952,3.729,3.729,0,0,1-2.679.168,7.836,7.836,0,0,1-2.632-1.075,3.137,3.137,0,0,1-1.254-2.173,32.374,32.374,0,0,1-.429-6.285,38.458,38.458,0,0,1,.68-6.475c.2-1.027.44-2.045.72-3.053.1-.359.133-.816.507-.962a1.731,1.731,0,0,1,.582-.043c1.1,0,2.192.037,3.286.067a12.451,12.451,0,0,1,2.6.106l-.365-.168h0C58.658,339.817,59.391,338.031,58.663,337.433Z"
                        transform="translate(3062.774 1547.655)"
                        fill={primaryColor}
                    />
                    <path
                        id="Path_463"
                        data-name="Path 463"
                        d="M486.421,339.166c-.1.083-.31.087-.144.079l.5-.024c.507-.021,1.015-.036,1.524-.051,1.065-.029,2.137-.056,3.207-.071a4.95,4.95,0,0,1,.983.019c.41.078.422.5.523.865.156.563.3,1.131.431,1.7a38.2,38.2,0,0,1,.912,6.318,36.536,36.536,0,0,1-.152,6.272,7.79,7.79,0,0,1-.627,2.884,3.8,3.8,0,0,1-1.987,1.526c-1.286.514-4.183,1.437-4.9-.433a5.14,5.14,0,0,1,.047-2.522q.228-1.561.4-3.131a48.294,48.294,0,0,0-.438-14.505c-.084-.422-.524-.884-.985-.767-.5.133-.542.753-.46,1.159a46.5,46.5,0,0,1,.228,14.736c-.253,2.061-.989,4.461.2,6.368,1.238,1.976,3.65,1.939,5.634,1.3a7.306,7.306,0,0,0,2.644-1.341,4.923,4.923,0,0,0,1.482-2.777,31.288,31.288,0,0,0,.5-7.129,41.193,41.193,0,0,0-.822-7.247,27.68,27.68,0,0,0-.861-3.449,2.329,2.329,0,0,0-2.232-1.814c-1.123-.036-2.262.033-3.38.063-.651.017-1.3.036-1.954.061-.322.013-.9-.061-1.17.156C484.795,338.015,485.607,339.832,486.421,339.166Z"
                        transform="translate(2662.663 1547.625)"
                        fill={primaryColor}
                    />
                    <path
                        id="Path_464"
                        data-name="Path 464"
                        d="M131.312,128.714a1.037,1.037,0,0,0,.136-.449,26.781,26.781,0,0,0-4.2-15.2,1.114,1.114,0,0,0-.1-.961,15.62,15.62,0,0,0-23.871-.884,2.147,2.147,0,0,0-.454,1.056,21.862,21.862,0,0,0-3.515,6.613,30.093,30.093,0,0,0-1.634,9.371,2.032,2.032,0,0,0-.1.365c-.78,4.9-.348,9.655.2,14.572.251,2.238,2.628-.058,2.463-1.518-.521-4.639-1-9.172-.272-13.729.212-1.332-.442-1.483-1.122-1.09a23.613,23.613,0,0,1,4.288-13.3c.159.033.372-.061.646-.361a14.338,14.338,0,0,1,21.78,1.052.537.537,0,0,0,.7.17,21.919,21.919,0,0,1,3,6.781,30.883,30.883,0,0,1,.985,5.8c-.748-.557-1.572-.527-1.338.947.732,4.6.246,9.123-.271,13.729-.164,1.46,2.21,3.756,2.463,1.518C131.643,138.307,132.072,133.586,131.312,128.714Z"
                        transform="translate(3020.377 1765.32)"
                        fill={primaryColor}
                    />
                </g>
            </g>
        </svg>
    );
}
