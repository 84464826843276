import React from 'react';

export default function MaleDoodle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="198"
            height="199.554"
            viewBox="0 0 198 199.554"
        >
            <g transform="translate(-86 -175.845)">
                <g transform="translate(126.373 234.899)">
                    <path
                        d="M0,17.1H30.37a29.706,29.706,0,0,1-15.186,4.283A28.84,28.84,0,0,1,0,17.1Zm0,0L4.338,0H24.947L30.37,17.1Z"
                        transform="translate(47.619 69.965)"
                        fill="#ffb8b8"
                    />
                    <g transform="translate(20.428 0)">
                        <path
                            d="M911.8,363.611c9.515,18.708,17.014,38,38,38a38,38,0,0,0,0-76.005C928.469,325.341,894.086,328.79,911.8,363.611Z"
                            transform="translate(-906.894 -325.595)"
                            fill="#0c0b26"
                        />
                        <ellipse
                            cx="33.335"
                            cy="33.335"
                            rx="33.335"
                            ry="33.335"
                            transform="translate(7.699 10.088)"
                            fill="#ffb8b8"
                        />
                        <path
                            d="M911.064,348.407a45.338,45.338,0,0,0,25.911,8.008,27.783,27.783,0,0,1-11.012,4.531,91.423,91.423,0,0,0,37.343.21,24.169,24.169,0,0,0,7.817-2.685,9.893,9.893,0,0,0,4.825-6.453c.819-4.68-2.828-8.933-6.618-11.8a48.816,48.816,0,0,0-41.021-8.2c-4.583,1.185-9.173,3.186-12.149,6.866s-3.858,9.353-1.022,13.143Z"
                            transform="translate(-905.404 -323.823)"
                            fill="#0c0b26"
                        />
                    </g>
                    <path
                        d="M58.99,64.06a94.311,94.311,0,0,1-31.8-5.48A94.113,94.113,0,0,1,0,43.4L10.909,12.3A18.511,18.511,0,0,1,28.335,0a18.605,18.605,0,0,1,3.453.325,17.4,17.4,0,0,1,2.348.622L34.2.906l.22-.127C42.3,5.86,52.968,8.437,66.139,8.438A127.428,127.428,0,0,0,95.388,4.753l.057.044.214.167a18.628,18.628,0,0,1,4.267-.6c.08,0,.164,0,.246-.006h.013c.077,0,.172-.007.259-.007a18.474,18.474,0,0,1,18.066,15.287l3.48,20.372A94.146,94.146,0,0,1,93.3,57.644,94.133,94.133,0,0,1,58.99,64.06Z"
                        transform="translate(0 76.44)"
                        fill="#edeffd"
                    />
                </g>
                <g
                    transform="translate(86 175.845)"
                    fill="none"
                    stroke="#e0e1e5"
                    strokeWidth="1"
                >
                    <ellipse
                        cx="99"
                        cy="99.5"
                        rx="99"
                        ry="99.5"
                        stroke="none"
                    />
                    <ellipse cx="99" cy="99.5" rx="98.5" ry="99" fill="none" />
                </g>
            </g>
        </svg>
    );
}
