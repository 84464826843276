import React, { useEffect, useState, Fragment } from 'react';
import OptList from '../../components/OptList';
import { scrollTop } from '../../helpers';
import FooterActions from '../../components/FooterActions';
export default function SelectableList(props) {
    const [buttonDisabled, setButtonState] = useState(true);
    const [answers, insertAnswer] = useState({});
    const [sectionsCount, setSectionsCount] = useState(0);

    const insertLocalAnswer = (key, value) => {
        const _answers = answers;
        _answers[key] = value;
        insertAnswer(_answers);
        if (Object.keys(answers).length === sectionsCount) {
            setButtonState(false);
        }
    };

    const data = props.content;
    const content = data.content.map((q) => {
        return (
            <OptList
                key={q.label}
                data={q}
                insertAnswer={insertLocalAnswer}
                answers={answers}
            />
        );
    });
    const handleNext = async () => {
        let RightNHHI = false;
        let LeftNHHI = false;
        setButtonState(true);
        let finalAnswers = {};
        for (const [key, value] of Object.entries(answers)) {
            finalAnswers[key] = value;
            if (key === 'RightNHHI' && value === 0) {
                RightNHHI = true;
            }
            if (key === 'LeftNHHI' && value === 0) {
                LeftNHHI = true;
            }
        }
        await props.insertMultiAnswers(finalAnswers);
        insertAnswer({});

        // if both ears ok skip to screen 15
        if (RightNHHI && LeftNHHI) {
            props.actionNext(15);
        } else {
            props.actionNext();
        }
    };

    useEffect(() => {
        if (props.content.content.length !== sectionsCount) {
            setSectionsCount(props.content.content.length);
        }

        if (
            sectionsCount > 0 &&
            Object.keys(answers).length === sectionsCount
        ) {
            setButtonState(false);
        }
    }, [props.content.content.length, sectionsCount, answers]);

    useEffect(() => {
        if (buttonDisabled === true) {
            scrollTop();
        }
    }, [buttonDisabled]);

    return (
        <Fragment>
            <main className="main screen">
                {data.title && <h1 className="screen-title">{data.title}</h1>}
                <div className="flex-wrap">{content}</div>
            </main>
            <FooterActions
                nextDisabled={buttonDisabled}
                actionNext={handleNext}
            />
        </Fragment>
    );
}
