import React from 'react';

export default function ReturningUserDoodle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="239.807"
            viewBox="0 0 300 239.807"
        >
            <g transform="translate(-47 -148.691)">
                <g transform="translate(47 93.477)">
                    <path
                        d="M135.508,0"
                        transform="translate(17.95 294.587)"
                        fill="none"
                        stroke="#3f3d56"
                        strokeWidth="1"
                    />
                    <path
                        d="M189.372,665.791a8.089,8.089,0,0,0-6.973,4.875c-1.743,4.214.5,9.165,3.871,12.238s7.7,4.83,11.632,7.139c5.283,3.1,10.03,7.406,12.673,12.933s2.924,12.372-.183,17.651c-2.884,4.9-8.128,7.833-13.129,10.538"
                        transform="translate(-176.391 -436.685)"
                        fill="none"
                        stroke="#3f3d56"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <ellipse
                        cx="6.159"
                        cy="2.992"
                        rx="6.159"
                        ry="2.992"
                        transform="translate(11.263 226.481)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="6.159"
                        cy="2.992"
                        rx="6.159"
                        ry="2.992"
                        transform="translate(16.191 246.191)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="6.159"
                        cy="2.992"
                        rx="6.159"
                        ry="2.992"
                        transform="translate(4.928 249.359)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="6.159"
                        cy="2.992"
                        rx="6.159"
                        ry="2.992"
                        transform="translate(22.526 265.902)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="6.159"
                        cy="2.992"
                        rx="6.159"
                        ry="2.992"
                        transform="translate(34.845 265.902)"
                        fill="#57b894"
                    />
                    <path
                        d="M210.084,697.77a6.606,6.606,0,0,1,5.694,3.981c1.424,3.442-.409,7.485-3.162,9.994s-6.287,3.944-9.5,5.83a25.461,25.461,0,0,0-10.35,10.562c-2.159,4.513-2.388,10.1.149,14.415,2.355,4,6.638,6.4,10.722,8.606"
                        transform="translate(-182.168 -456.577)"
                        fill="none"
                        stroke="#3f3d56"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <ellipse
                        cx="5.03"
                        cy="2.443"
                        rx="5.03"
                        ry="2.443"
                        transform="translate(19.259 239.05)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="5.03"
                        cy="2.443"
                        rx="5.03"
                        ry="2.443"
                        transform="translate(15.234 255.146)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="5.03"
                        cy="2.443"
                        rx="5.03"
                        ry="2.443"
                        transform="translate(24.433 257.733)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="5.03"
                        cy="2.443"
                        rx="5.03"
                        ry="2.443"
                        transform="translate(10.06 271.243)"
                        fill="#57b894"
                    />
                    <ellipse
                        cx="5.03"
                        cy="2.443"
                        rx="5.03"
                        ry="2.443"
                        transform="translate(0 271.243)"
                        fill="#57b894"
                    />
                    <path
                        d="M293.923,795.939a25.316,25.316,0,0,0,5.983,2.112c2.464.352,3.85-2.14,3.333-3.006s1.595-.162,3,3.006c0,0,5.983,7.039,9.5,7.391s3.547,5.117,1.76,5.28c-1.936.176-5.28,1.408-9.5,0s-8.1-5.28-9.855-5.28-7.743-2.816-5.983-4.224S293.923,795.939,293.923,795.939Z"
                        transform="translate(-244.844 -516.872)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M387.858,811.219a19.547,19.547,0,0,0,5.37-.33c2.418-.588,2.78-3.416,1.979-4.028s1.421-.742,3.9,1.677c0,0,8.169,4.315,11.568,3.336s5.193,3.435,3.594,4.249a20.39,20.39,0,0,1-8.816,2.488c-4.444.26-9.484-.858-11.119-.2s-8.235.26-7.124-1.7S387.858,811.219,387.858,811.219Z"
                        transform="translate(-304.089 -524.199)"
                        fill="#3f3d56"
                    />
                    <circle
                        cx="13.727"
                        cy="13.727"
                        r="13.727"
                        transform="translate(64.059 91.677)"
                        fill="#ffc1c7"
                    />
                    <path
                        d="M339.356,351.916s2.112,14.783-2.816,16.895,24.99,1.408,24.99,1.408-4.224-13.375-3.168-16.895S339.356,351.916,339.356,351.916Z"
                        transform="translate(-272.306 -241.057)"
                        fill="#ffc1c7"
                    />
                    <path
                        d="M309.846,571.386s-1.056,26.75-2.112,29.565-2.112,21.822-2.112,21.822l-9.151,34.493,8.447,2.816s11.615-20.414,13.023-22.878,12.671-41.884,12.671-41.884-1.056,24.286-1.056,32.381,2.112,37.661,2.112,37.661l6.687-.7,8.8-37.309s12.671-50.332,4.224-55.963S309.846,571.386,309.846,571.386Z"
                        transform="translate(-247.723 -376.406)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M458.349,594.957s.352,20.414-3.872,17.247,0-19.006,0-19.006Z"
                        transform="translate(-344.839 -391.53)"
                        fill="#ffc1c7"
                    />
                    <path
                        d="M303.771,576.333s.352,20.414-3.872,17.247,0-19.006,0-19.006Z"
                        transform="translate(-248.688 -379.945)"
                        fill="#ffc1c7"
                    />
                    <path
                        d="M350.885,410.117s-7.039-8.1-13.023-7.039-38.717.7-38.717.7-10.911,13.727-8.1,16.543,11.967,52.8,11.967,52.8,24.286-3.168,32.733-3.168,11.967-1.408,11.967-1.408-11.263-22.526-5.983-32.733a209.262,209.262,0,0,1,13.023-21.118Z"
                        transform="translate(-244.062 -273.212)"
                        fill="#6c63ff"
                    />
                    <path
                        d="M428.906,428.376l5.983,2.112s3.52,12.319,3.52,22.174.352,14.783,1.76,18.3,5.984,20.062,3.52,23.582a23.468,23.468,0,0,1-4.928,5.28l-8.1-.7s-.352-11.615-2.464-12.671-4.928-7.743-4.928-7.743-6.687-21.47-2.464-28.51S428.906,428.376,428.906,428.376Z"
                        transform="translate(-324.195 -289.007)"
                        fill="#6c63ff"
                    />
                    <path
                        d="M314.719,438.464s3.52-15.487-3.168-14.783-15.839,1.056-18.3,1.056-16.543-1.76-18.654,7.391-4.928,20.414,1.056,27.454c0,0,2.112,30.973,10.207,33.085s29.565,4.576,37.309-5.631a26.251,26.251,0,0,0,4.576-21.47h-3.52Z"
                        transform="translate(-232.534 -286.073)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M248.163,444.649l2.816-19.007-1.408,19.71Z"
                        transform="translate(-154.363 -264.451)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M290.883,525.22s5.983,2.816,18.3,1.056v5.28l-18.3-1.408Z"
                        transform="translate(-244.247 -349.247)"
                        opacity="0.1"
                    />
                    <path
                        d="M208.587,354.678l9.151-4.928-7.743,6.688Z"
                        transform="translate(-129.746 -217.245)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M352,390.272s7.039,2.464,7.039,0-6.335-4.576-10.207-5.983-22.174-3.52-24.99-4.576-16.191-2.816-17.246,1.408-1.408,13.023,3.872,14.079,19.006-1.76,26.046-1.056S352,390.272,352,390.272Z"
                        transform="translate(-253.621 -257.943)"
                        fill="#6c63ff"
                    />
                    <path
                        d="M352,390.272s7.039,2.464,7.039,0-6.335-4.576-10.207-5.983-22.174-3.52-24.99-4.576-16.191-2.816-17.246,1.408-1.408,13.023,3.872,14.079,19.006-1.76,26.046-1.056S352,390.272,352,390.272Z"
                        transform="translate(-253.621 -257.943)"
                        opacity="0.1"
                    />
                    <path
                        d="M130.367,361.967l2.392-5.025.424,6.081Z"
                        transform="translate(-81.091 -221.718)"
                        fill="#3f3d56"
                    />
                    <ellipse
                        cx="12.851"
                        cy="14.93"
                        rx="12.851"
                        ry="14.93"
                        transform="translate(62.555 89.711)"
                        fill="#2f2e41"
                    />
                    <ellipse
                        cx="12.851"
                        cy="8.127"
                        rx="12.851"
                        ry="8.127"
                        transform="translate(64.823 88.578)"
                        fill="#2f2e41"
                    />
                    <ellipse
                        cx="1.323"
                        cy="2.268"
                        rx="1.323"
                        ry="2.268"
                        transform="translate(86.368 106.72)"
                        fill="#ffc1c7"
                    />
                    <path
                        d="M394.618,303.563a8.379,8.379,0,0,0,3.371-3.905,4.121,4.121,0,0,1,.068,1.236,4.68,4.68,0,0,0,1.609-.485l-.121,1.317A13.591,13.591,0,0,0,402.2,299.6a10.46,10.46,0,0,1-.931,5.616,11.23,11.23,0,0,1-2.6,3.172"
                        transform="translate(-308.773 -208.905)"
                        fill="#2f2e41"
                    />
                </g>
                <g transform="matrix(-1, 0, 0, 1, 347, 148.691)">
                    <path
                        d="M290.779,78.134a10.32,10.32,0,0,0-10.308,10.308V306.981a10.32,10.32,0,0,0,10.308,10.308H386.45a10.32,10.32,0,0,0,10.308-10.308V88.442A10.32,10.32,0,0,0,386.45,78.134Z"
                        transform="translate(-261.32 -78.134)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M527.921,157.412V139.876a40.346,40.346,0,0,1-26.047-19.388h0A7.663,7.663,0,0,1,497.293,122H483.368A57.515,57.515,0,0,0,527.921,157.412Z"
                        transform="translate(-398.858 -106.844)"
                        fill="#fff"
                    />
                    <path
                        d="M568.464,122.358V105.512a9.381,9.381,0,0,0-9.383-9.384H545.56v1.627a7.7,7.7,0,0,1-2.383,5.582A39.4,39.4,0,0,0,568.464,122.358Z"
                        transform="translate(-439.401 -90.332)"
                        fill="#fff"
                    />
                    <path
                        d="M338.767,105.483h-8.707a7.727,7.727,0,0,1-7.725-7.728V96.128H309.649a9.383,9.383,0,0,0-9.383,9.384v208.8a9.367,9.367,0,0,0,9.383,9.377h84.767a9.133,9.133,0,0,0,1.156-.071,9.389,9.389,0,0,0,8.227-9.306V160.6A77.159,77.159,0,0,1,338.767,105.483Z"
                        transform="translate(-274.738 -90.332)"
                        fill="#fff"
                    />
                    <path
                        d="M441.354,125.168H422.947a76.178,76.178,0,0,0,64.016,54.139V161.584A58.52,58.52,0,0,1,441.354,125.168Z"
                        transform="translate(-357.9 -110.017)"
                        fill="#fff"
                    />
                    <path
                        d="M415.214,558.736H328.24a1.612,1.612,0,0,1-1.611-1.611V535.551a1.613,1.613,0,0,1,1.611-1.611h86.974a1.612,1.612,0,0,1,1.611,1.611v21.574A1.612,1.612,0,0,1,415.214,558.736ZM328.24,534.584a.967.967,0,0,0-.966.966v21.574a.968.968,0,0,0,.966.966h86.974a.968.968,0,0,0,.966-.966V535.551a.968.968,0,0,0-.966-.966Z"
                        transform="translate(-292.609 -387.113)"
                        fill="#e6e6e6"
                    />
                    <circle
                        cx="6.765"
                        cy="6.765"
                        r="6.765"
                        transform="translate(40.14 152.46)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M412.756,558.427a1.127,1.127,0,0,0,0,2.255h53.151a1.127,1.127,0,0,0,0-2.255Z"
                        transform="translate(-350.228 -403.712)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M412.756,579.427a1.128,1.128,0,0,0,0,2.255h53.151a1.127,1.127,0,0,0,0-2.255Z"
                        transform="translate(-350.228 -417.947)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M415.214,670.735H328.24a1.612,1.612,0,0,1-1.611-1.61V647.55a1.612,1.612,0,0,1,1.611-1.611h86.974a1.613,1.613,0,0,1,1.611,1.611v21.575A1.612,1.612,0,0,1,415.214,670.735ZM328.24,646.584a.967.967,0,0,0-.966.966v21.575a.968.968,0,0,0,.966.966h86.974a.968.968,0,0,0,.966-.966V647.55a.968.968,0,0,0-.966-.966Z"
                        transform="translate(-292.609 -463.035)"
                        fill="#e6e6e6"
                    />
                    <circle
                        cx="6.765"
                        cy="6.765"
                        r="6.765"
                        transform="translate(40.14 188.538)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M412.756,670.427a1.127,1.127,0,0,0,0,2.255h53.151a1.127,1.127,0,0,0,0-2.255Z"
                        transform="translate(-350.228 -479.634)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M412.756,691.427a1.127,1.127,0,0,0,0,2.255h53.151a1.127,1.127,0,0,0,0-2.255Z"
                        transform="translate(-350.228 -493.869)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M396.549,343.134a30.589,30.589,0,0,1-30.6-30.6c0-.064,0-.132,0-.2a30.6,30.6,0,1,1,44.187,27.623A30.315,30.315,0,0,1,396.549,343.134Zm0-60.56a30.021,30.021,0,0,0-29.954,29.782c0,.068,0,.123,0,.175a29.962,29.962,0,1,0,29.958-29.958Z"
                        transform="translate(-319.262 -216.282)"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M457.859,355.9l-20.945-12.093a.644.644,0,0,0-.966.558V368.55a.644.644,0,0,0,.966.558l20.945-12.093a.644.644,0,0,0,0-1.116l-20.945-12.093a.644.644,0,0,0-.966.558V368.55a.644.644,0,0,0,.966.558l20.945-12.093a.644.644,0,0,0,0-1.116Z"
                        transform="translate(-366.713 -258.168)"
                        fill="#4357e7"
                    />
                    <path
                        d="M279.854,742.253H0v-.7H280Z"
                        transform="translate(0 -502.678)"
                        fill="#3f3d56"
                    />
                </g>
            </g>
        </svg>
    );
}
