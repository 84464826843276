import React, { useState, useEffect } from 'react';
import './Slider.css';
export default function Slider(props) {
    const [step, setStep] = useState();
    useEffect(() => {
        let playerState = props.playerState;
        if (playerState === 'paused') {
            setStep(0);
        } else {
            setStep(1);
        }
    }, [props.playerState, props.step]);
    return (
        <div className="audio-slider">
            {!props.hideControllers && (
                <div className="labels">
                    <button
                        className="slider-controller"
                        aria-label={props.labelIncrease}
                        onClick={() => {
                            if (props.increaseAction && step !== 0) {
                                props.increaseAction();
                            }
                        }}
                    >
                        +
                    </button>
                    <button
                        className="slider-controller"
                        aria-label={props.labelDecrease}
                        onClick={() => {
                            if (props.decreaseAction && step !== 0) {
                                props.decreaseAction();
                            }
                        }}
                    >
                        -
                    </button>
                </div>
            )}
            <input
                dir="ltr"
                type="range"
                id="volume-control"
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value}
                onChange={(e) => {
                    if (props.sliderAction && step !== 0) {
                        props.sliderAction(e.currentTarget.value);
                    }
                }}
            ></input>
        </div>
    );
}
