import React from 'react';
import './ProgressText.css';

export default function ProgressText(props) {
    return (
        <p className="progress-text">
            {props.section && <span>חלק {props.section}/7</span>}
            {props.questionNumber !== '' && (
                <span>
                    שאלה {props.questionNumber[0]} מתוך{' '}
                    {props.questionNumber[1]}
                </span>
            )}
        </p>
    );
}
