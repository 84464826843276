import React, { useEffect } from 'react';
import { scrollTop } from '../../helpers';
import FooterActions from '../../components/FooterActions';
export default function ScreenText(props) {
    const { content } = props;

    const handleNext = () => {
        props.actionNext();
    };
    useEffect(() => {
        let loaded = true;
        if (loaded) {
            scrollTop();
        }
        return function cleanup() {
            loaded = false;
        };
    }, []);

    return (
        <React.Fragment>
            <main className="main screen">
                {content.title && (
                    <h1 className="screen-title">{content.title}</h1>
                )}
                {content.content && (
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: content.content }}
                    ></div>
                )}
            </main>
            {!props.hideFooter && (
                <FooterActions
                    actionNext={handleNext}
                    startButton={
                        content.start_button ? content.start_button : false
                    }
                    nextText={content.next_text ? content.next_text : ''}
                />
            )}
        </React.Fragment>
    );
}
