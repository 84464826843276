import React from 'react';

export default function BreakDoodle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="303.218"
            height="254.992"
            viewBox="0 0 303.218 254.992"
        >
            <g transform="translate(0)">
                <path
                    d="M319.271,123a86.367,86.367,0,0,0-65.163,29.185A86.367,86.367,0,0,0,188.946,123c-48.632.223-87.391,40.259-86.429,89.224.933,47.489,39.453,85.7,86.834,85.7q2.757,0,5.473-.171a948.031,948.031,0,0,1,118.569,0q2.715.17,5.473.171c47.381,0,85.9-38.208,86.834-85.7C406.663,163.261,367.9,123.224,319.271,123Z"
                    transform="translate(-102.499 -95.154)"
                    fill="#f5f9fc"
                />
                <path
                    d="M744.911,39.083a7,7,0,0,0-6.037,4.221c-1.51,3.649.434,7.935,3.352,10.6s6.666,4.182,10.071,6.181c4.574,2.685,8.684,6.413,10.973,11.2s2.532,10.712-.158,15.283c-2.5,4.243-7.037,6.782-11.368,9.124"
                    transform="translate(-544.58 -36.81)"
                    fill="none"
                    stroke="#3f3d56"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(198.845)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(203.111 17.066)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(193.359 19.808)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(208.596 34.131)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(219.262 34.131)"
                    fill="#57b894"
                />
                <path
                    d="M808.94,316.586a7,7,0,0,0-7.284,1.1c-2.974,2.6-3.137,7.3-1.706,10.982s4.113,6.708,6.275,10.013c2.9,4.438,4.93,9.6,4.854,14.907s-2.492,10.721-6.933,13.621c-4.122,2.691-9.318,2.948-14.238,3.122"
                    transform="translate(-580.426 -229.354)"
                    fill="none"
                    stroke="#3f3d56"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <ellipse
                    cx="2.59"
                    cy="5.333"
                    rx="2.59"
                    ry="5.333"
                    transform="matrix(0.444, -0.896, 0.896, 0.444, 225.888, 89.176)"
                    fill="#57b894"
                />
                <ellipse
                    cx="2.59"
                    cy="5.333"
                    rx="2.59"
                    ry="5.333"
                    transform="matrix(0.444, -0.896, 0.896, 0.444, 222.126, 106.359)"
                    fill="#57b894"
                />
                <ellipse
                    cx="2.59"
                    cy="5.333"
                    rx="2.59"
                    ry="5.333"
                    transform="matrix(0.444, -0.896, 0.896, 0.444, 212.172, 104.483)"
                    fill="#57b894"
                />
                <ellipse
                    cx="2.59"
                    cy="5.333"
                    rx="2.59"
                    ry="5.333"
                    transform="matrix(0.444, -0.896, 0.896, 0.444, 219.457, 124.085)"
                    fill="#57b894"
                />
                <ellipse
                    cx="2.59"
                    cy="5.333"
                    rx="2.59"
                    ry="5.333"
                    transform="matrix(0.444, -0.896, 0.896, 0.444, 229.011, 128.825)"
                    fill="#57b894"
                />
                <path
                    d="M400.5,393.389a7,7,0,0,1,7.175-1.669c3.723,1.316,5.612,5.626,5.641,9.575s-1.345,7.753-2.134,11.622c-1.06,5.2-1.034,10.746,1,15.646s6.275,9.043,11.474,10.1c4.825.978,9.748-.7,14.384-2.358"
                    transform="translate(-309.689 -281.728)"
                    fill="none"
                    stroke="#3f3d56"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(82.989 116.969) rotate(-48.059)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(92.832 131.549) rotate(-48.059)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(101.39 126.128) rotate(-48.059)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(101.86 147.035) rotate(-48.059)"
                    fill="#57b894"
                />
                <ellipse
                    cx="5.333"
                    cy="2.59"
                    rx="5.333"
                    ry="2.59"
                    transform="translate(94.732 154.969) rotate(-48.059)"
                    fill="#57b894"
                />
                <path
                    d="M574.89,269.751l-.838,6.147-5.181,37.928-8.484,62.113-2.124,15.551a4.954,4.954,0,0,1-4.909,4.285H496.778a4.955,4.955,0,0,1-4.909-4.272l-2.161-15.563-8.627-62.113-5.272-37.956-.847-6.107a4.954,4.954,0,0,1,4.906-5.638H569.98a4.957,4.957,0,0,1,4.909,5.626Z"
                    transform="translate(-361.424 -193.273)"
                    fill="#4357e7"
                />
                <rect
                    width="88.375"
                    height="16.456"
                    rx="8.228"
                    transform="translate(119.459 54.396)"
                    fill="#2f2e41"
                />
                <path
                    d="M582.875,235.646v3.724a4.955,4.955,0,0,1-4.955,4.955H499.455a4.955,4.955,0,0,1-4.955-4.955v-3.715a465.34,465.34,0,0,0,88.375-.009Z"
                    transform="translate(-375.041 -173.472)"
                    opacity="0.1"
                />
                <path
                    d="M574.107,266.343c-.06-.09-.123-.177-.188-.262l-.067-.085a4.994,4.994,0,0,0-.632-.658c-.051-.045-.1-.089-.156-.131s-.12-.095-.182-.139c-.078-.056-.158-.109-.238-.161-.064-.041-.128-.081-.194-.119q-.152-.088-.311-.166c-.051-.025-.1-.047-.153-.07q-.152-.067-.309-.125c-.042-.015-.084-.032-.127-.046q-.218-.073-.445-.125c-.037-.009-.075-.015-.112-.022q-.185-.038-.376-.063l-.138-.016c-.163-.016-.327-.027-.494-.027H479.873a4.968,4.968,0,0,0-4.907,5.636l.143,1.03.7,5.078h0l5.271,37.956h0l6.92,49.822,3.868,27.853a4.955,4.955,0,0,0,4.908,4.273h2.685a4.948,4.948,0,0,1-.279-1.073l-2.606-18.762h63.81l8.484-62.113h-80.92l-5.187-37.348a523.665,523.665,0,0,0,91.288-.58l.838-6.147Z"
                    transform="translate(-361.428 -193.273)"
                    opacity="0.1"
                />
                <path
                    d="M555.271,253.582a524.462,524.462,0,0,1-113.816,0,4.955,4.955,0,0,1-4.955-4.955v-6.546a4.955,4.955,0,0,1,4.955-4.955,466.332,466.332,0,0,0,113.816,0,4.955,4.955,0,0,1,4.955,4.955v6.546A4.955,4.955,0,0,1,555.271,253.582Z"
                    transform="translate(-334.716 -174.501)"
                    fill="#2f2e41"
                />
                <line
                    x2="210.577"
                    transform="translate(38.55 202.653)"
                    fill="none"
                    stroke="#3f3d56"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    d="M286.152,604.07v8.573l4.4,5.1s1.854-.7,1.854-5.329l.7-6.719Z"
                    transform="translate(-230.185 -429.622)"
                    fill="#ffb8b8"
                />
                <path
                    d="M263.2,603.929s-7.182-4.865-7.414-6.487-10.426-9.036-8.8-4.4,6.719,6.719,6.719,6.719,3.939,10.658,7.414,10.426S264.587,605.551,263.2,603.929Z"
                    transform="translate(-202.828 -420.908)"
                    fill="#ffb8b8"
                />
                <path
                    d="M204.911,771.64l.7,4.17-3.707-.927V771.64Z"
                    transform="translate(-140.372 -536.489)"
                    fill="#3f3d56"
                />
                <path
                    d="M292.421,807.023s-4.285-2.538-4.459-1.385-2.028,5.555-2.955,6.019-2.085,5.561,0,5.329c1.615-.179,7.539-3.278,10.128-4.666a1.415,1.415,0,0,0,.608-1.849,2.921,2.921,0,0,1-.31-.9C295.433,809.109,292.421,807.023,292.421,807.023Z"
                    transform="translate(-228.576 -569.555)"
                    fill="#2f2e41"
                />
                <path
                    d="M283.707,648.073s-16.913,7.878-15.292,13.9,7.646,31.51,7.646,31.51,6.719,1.622,7.182,0-4.4-26.876-3.939-27.8,9.036-6.951,9.036-6.951l17.609-7.414-7.414-6.951Z"
                    transform="translate(-217.777 -457.638)"
                    fill="#516371"
                />
                <path
                    d="M169.771,776.2l-2.085,5.329,3.707,1.622,2.317-4.171Z"
                    transform="translate(-116.585 -539.661)"
                    fill="#3f3d56"
                />
                <path
                    d="M317.169,516.2s2.085,2.78.463,4.17-.463,6.487-.463,6.487l2.085,5.792,8.341-1.622v-9.5l-.463-4.634s-4.17-3.939-4.634-6.951S317.169,516.2,317.169,516.2Z"
                    transform="translate(-251.472 -363.76)"
                    fill="#ffb8b8"
                />
                <path
                    d="M317.169,516.2s2.085,2.78.463,4.17-.463,6.487-.463,6.487l2.085,5.792,8.341-1.622v-9.5l-.463-4.634s-4.17-3.939-4.634-6.951S317.169,516.2,317.169,516.2Z"
                    transform="translate(-251.472 -363.76)"
                    fill="#0c0b26"
                    opacity="0.1"
                />
                <path
                    d="M299.741,650.448s-10.889,12.048-10.889,12.511-5.792,9.963-5.792,9.963l-11.353,25.254s4.4,6.024,6.256,5.561,15.06-27.34,15.06-27.34,2.317-6.024,8.573-7.414,11.121-6.256,8.8-11.816S299.741,650.448,299.741,650.448Z"
                    transform="translate(-220.142 -461.867)"
                    fill="#516371"
                />
                <circle
                    cx="7.414"
                    cy="7.414"
                    r="7.414"
                    transform="translate(58.284 139.231)"
                    fill="#ffb8b8"
                />
                <path
                    d="M315.672,549.2s.927,5.329,1.854,5.792,1.622,2.085,3.707-.232,3.939-6.951,3.939-6.951l7.182,8.8.7,24.328s-8.109-2.085-10.658,0-8.109,1.854-8.109,1.854l1.158-4.634-.927-7.878.232-7.182.232-5.1Z"
                    transform="translate(-249.743 -390.506)"
                    fill="#f2f2f2"
                />
                <path
                    d="M300.1,539.573s-3.133,1.583-3.365,2.51-4.4,2.78-4.4,2.78-1.622,1.622-1.622,2.549S296.275,559,296.275,559s1.158,7.182.7,8.573.927,6.719.927,6.719l-2.78,3.244h2.549s2.78-1.39,2.78-2.085-.463-5.561-.7-7.646a70.621,70.621,0,0,1,.232-12.048c.463-2.549.232-9.268.232-10.89S300.1,539.573,300.1,539.573Z"
                    transform="translate(-233.357 -384.78)"
                    fill="#0c0b26"
                />
                <path
                    d="M336.367,528.029s1.043.236,4.055,1.162,7.646,2.317,9.268,4.17a28.726,28.726,0,0,1,3.012,4.4l-.927,16.682s1.854,3.475,1.622,5.329-2.085,2.085-.7,6.024,4.17,7.414,1.622,7.878-9.268-1.158-12.28-.232-6.487-3.475-6.487-3.475,4.634.463,3.939-1.39-8.341-22.706-6.719-27.34S336.367,528.029,336.367,528.029Z"
                    transform="translate(-262.444 -376.754)"
                    fill="#0c0b26"
                />
                <path
                    d="M286.206,561.494l-1.158.927s-3.244,11.585-2.317,12.743,8.341,3.707,8.8,2.085S286.206,561.494,286.206,561.494Z"
                    transform="translate(-227.691 -400.021)"
                    fill="#0c0b26"
                />
                <path
                    d="M391.709,588.1s3.475,9.963,3.475,12.743-.463,19-.463,19-.232,6.951-5.1,4.17,0-4.634,0-4.634-.232-18.3-2.085-20.852a18,18,0,0,1-2.549-6.256Z"
                    transform="translate(-298.903 -418.522)"
                    fill="#ffb8b8"
                />
                <path
                    d="M263.758,822.317s-3.475-1.853-4.17-.463-4.4,9.036-3.939,9.963,3.012,4.634,6.024,3.707,3.244-5.792,3.244-5.792l.232-5.561Z"
                    transform="translate(-208.95 -580.679)"
                    fill="#2f2e41"
                />
                <path
                    d="M288.021,485.461a3.263,3.263,0,0,1,1.438.848,7.15,7.15,0,0,1,.435,1.172,1.657,1.657,0,0,0,2.132.78,1.838,1.838,0,0,1,.75-1.138,1.092,1.092,0,0,1,1.3.071c.478.455.309,1.249.531,1.87a3.948,3.948,0,0,0,.849,1.164,11.98,11.98,0,0,0,1.522,1.747,1.8,1.8,0,0,0,2.144.2,1.831,1.831,0,0,0,.492-1.79c-.124-.639-.431-1.228-.6-1.855-.563-2.039.335-4.216.057-6.314-.356-2.687-2.64-4.81-5.189-5.731a6.071,6.071,0,0,0-3.35-.355,25.073,25.073,0,0,0-3.279,1.427,24.931,24.931,0,0,1-3.742.8,5.122,5.122,0,0,0-3.261,1.83,23.579,23.579,0,0,0-1.1,2.431,4.361,4.361,0,0,1-2.209,1.979l1.321-.075a3.894,3.894,0,0,1-1.053,1.536,2.825,2.825,0,0,0,1.829-.242,1.988,1.988,0,0,1-.682,1.425,5.749,5.749,0,0,0,2.947-.622c.421-.267.764-.638,1.16-.94a8.581,8.581,0,0,1,2.911-1.243c.536-.154,1.181-.368,1.666-.023C287.435,484.7,287.5,485.215,288.021,485.461Z"
                    transform="translate(-223.775 -340.607)"
                    fill="#2f2e41"
                />
                <path
                    d="M377.227,550.85l3.475,2.78,4.634,10.658s-6.487,5.1-8.109,4.4-4.4-12.975-4.4-12.975Z"
                    transform="translate(-290.445 -392.621)"
                    fill="#0c0b26"
                />
                <path
                    d="M258.106,585.283l-.058.426-.359,2.628-.588,4.3-.147,1.077a.343.343,0,0,1-.34.3h-3.92a.343.343,0,0,1-.34-.3l-.15-1.078-.6-4.3-.365-2.63-.059-.423a.343.343,0,0,1,.34-.391h6.244a.343.343,0,0,1,.34.39Z"
                    transform="translate(-205.87 -416.289)"
                    fill="#4357e7"
                />
                <rect
                    width="6.123"
                    height="1.14"
                    rx="0.57"
                    transform="translate(45.723 167.464)"
                    fill="#2f2e41"
                />
                <path
                    d="M256.747,584.162a36.346,36.346,0,0,1-7.886,0,.343.343,0,0,1-.343-.343v-.454a.343.343,0,0,1,.343-.343,32.313,32.313,0,0,0,7.886,0,.343.343,0,0,1,.343.343v.454a.343.343,0,0,1-.343.343Z"
                    transform="translate(-204.02 -414.988)"
                    fill="#2f2e41"
                />
                <path
                    d="M257.344,600.7h-5.278a.2.2,0,0,1-.2-.168l-.57-3.593a.2.2,0,0,1,.2-.23h6.457a.2.2,0,0,1,.2.232l-.608,3.593A.2.2,0,0,1,257.344,600.7Z"
                    transform="translate(-205.952 -424.502)"
                    fill="#2f2e41"
                />
            </g>
        </svg>
    );
}
