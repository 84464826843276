import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Howl } from 'howler';
import { dbToLinear, linearToDb, scrollTop } from '../../helpers';
import GenderDoodle from '../../components/GenderDoodle';
import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
import FooterActions from '../../components/FooterActions';
import AudioDescription from '../../components/AudioDescription';

export default function AudioVolume(props) {
    const debug = props.debug;
    const maxDbValue = 0;
    const minDbValue = -140;
    const step = 1;
    const gender = useRef();
    const speaker = gender.current === 'male' ? 'דובר' : 'דוברת';
    const soundsText = gender.current === 'male' ? 'ישמע' : 'תשמע';
    const [player, setPlayer] = useState(null);
    const [playerState, setPlayerState] = useState('paused');
    const [fileLoaded, setFileLoaded] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const [db, setDbValue] = useState(linearToDb(0.5));
    const [shouldIncreaseIndex, setShouldIncreaseIndex] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const audioIndex = useRef();
    const audioList = props.audio;
    const initialProps = props;

    const handleNext = async () => {
        player.unload();
        setCanGoNext(false);
        setFileLoaded(false);
        const currentGender = gender.current === 'male' ? 'male' : 'female';
        const isMale = currentGender === 'male';
        let Llabel = isMale ? 'Lm' : 'Lf';
        const Slabel = isMale ? 'Sm' : 'Sf';
        await props.insertMultiAnswers({
            [Llabel]: dbToLinear(db),
            [Slabel]: Number(Number(db).toFixed(2)),
        });
        if (shouldIncreaseIndex) {
            await initialProps.increaseGenderIndex(currentGender);
        }
        setPlayer(null);
        initialProps.actionNext();
    };

    const increaseVoiceOverIndex = useCallback(() => {
        initialProps.increaseGenderIndex(gender.current === 'male' ? 'male' : 'female');
        audioIndex.current++;
        setAudioFile(audioList[gender.current][audioIndex.current].location);
        setShouldIncreaseIndex(false);
        setFileLoaded(false);
    }, [initialProps, audioList]);

    useEffect(() => {
        if (gender.current !== props.content.gender) {
            scrollTop();
            const currentGender = props.content.gender;
            gender.current = currentGender;
            audioIndex.current = currentGender === 'male'
                    ? props.maleIndex
                    : props.femaleIndex;
            setAudioFile(
                audioList[currentGender][audioIndex.current].location
            );
            setFileLoaded(false);
            setDbValue(linearToDb(0.5));
        }
        return function cleanup() {
            setAudioFile(null);
        };
    }, [
        props.content.gender,
        audioList,
        props.maleIndex,
        props.femaleIndex,
        audioFile,
    ]);

    useEffect(() => {
        if (player && player.state && player.state('loaded')) {
            player.volume(dbToLinear(db));
            if (!debug) {
                return;
            }
            
            console.log(
                `dB - Min: ${minDbValue}, Max: ${maxDbValue}, Current: ${Number(
                    db
                )}`
            );
            console.log(
                `Linear - Min: ${dbToLinear(minDbValue)}, Max: ${dbToLinear(
                    maxDbValue
                )}, Current: ${dbToLinear(db)}`
            );
        }
    }, [player, db, minDbValue, maxDbValue, debug]);

    useEffect(() => {
        if (audioFile !== null) {
            setPlayer(
                new Howl({
                    src: audioFile,
                    onload: () => {
                        setFileLoaded(true);

                        if (debug) {
                            console.log(
                                `Player Loaded. Current File: ${audioFile}`
                            );
                        }
                    },
                    onplay: () => {
                        setPlayerState('playing');
                        setShouldIncreaseIndex(true);
                        setCanGoNext(true);
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                        increaseVoiceOverIndex();
                    },
                })
            );
        }
    }, [audioFile, increaseVoiceOverIndex, debug]);

    return (
        <React.Fragment>
            <main className="main screen">
                <GenderDoodle gender={gender.current} />
                <h1 className="screen-title">
                    עוצמת שמע מועדפת בהאזנה ל{speaker}
                </h1>
                <p style={{ textAlign: 'center' }}>
                    בהישמע ה{speaker}, יש להזיז את הנקודה הכחולה ימינה ושמאלה{' '}
                    <strong>
                        עד שה{speaker} {soundsText} באופן ברור, נוח והנעים ביותר
                        עבורך
                    </strong>
                </p>
                <AudioDescription speaker={speaker} />

                <PlayerControllers
                    playerState={playerState}
                    audioLoaded={fileLoaded}
                    play={() => {
                        if (player) {
                            player.play();
                        }
                    }}
                    pause={() => {
                        if (player) {
                            player.pause();
                        }
                    }}
                />
                <Slider
                    min={minDbValue}
                    max={maxDbValue}
                    step={step}
                    labelIncrease="הגבר ווליום"
                    labelDecrease="הנמך ווליום"
                    playerState={playerState}
                    value={db}
                    sliderAction={(value) => {
                        setDbValue(value);
                    }}
                    increaseAction={() => {
                        setDbValue(
                            Number(db) + step >= maxDbValue
                                ? maxDbValue
                                : Number(db) + step
                        );
                    }}
                    decreaseAction={() => {
                        setDbValue(
                            Number(db) - step <= minDbValue
                                ? minDbValue
                                : Number(db) - step
                        );
                    }}
                />

                <DoNotChangeNotice />
            </main>

            <FooterActions
                actionNext={handleNext}
                nextDisabled={canGoNext === true ? false : true}
            />
        </React.Fragment>
    );
}
