import React from 'react';

export default function Load() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            className="rotating"
        >
            <g transform="translate(-142 -549)">
                <circle cx="30" cy="30" r="30" transform="translate(142 549)" />
                <g transform="translate(151 558)">
                    <path
                        d="M18,3v7.094"
                        transform="translate(2.734)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M18,27v7.094"
                        transform="translate(2.734 4.374)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M7.395,7.395l5.019,5.019"
                        transform="translate(0.801 0.801)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M24.36,24.36l5.019,5.019"
                        transform="translate(3.893 3.893)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M3,18h7.094"
                        transform="translate(0 2.734)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M27,18h7.094"
                        transform="translate(4.374 2.734)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M7.395,29.379l5.019-5.019"
                        transform="translate(0.801 3.893)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                    <path
                        d="M24.36,12.414l5.019-5.019"
                        transform="translate(3.893 0.801)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="5"
                    />
                </g>
            </g>
        </svg>
    );
}
