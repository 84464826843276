import React, { useState, useEffect, useRef } from 'react';
import { Howl } from 'howler';
import {
    arrayShuffle,
    dbToLinear,
    // linearToDb,
    scrollTop,
    setMaxDbVolume,
    setMinDbVolume,
    numberToFixed,
} from '../../helpers';
import { useInterval } from '../../hooks/useInterval';

import FooterActions from '../../components/FooterActions';
import PlayerControllers from '../../components/PlayerControllers';
import AudioDescription from '../../components/AudioDescription';
import Slider from '../../components/Slider';
import './style.css';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';

export default function VolumeRangeToNoise(props) {
    const debug = props.debug;
    const step = 1;
    const noiseStack = useRef(arrayShuffle(Object.entries(props.audioNoises)));
    const voiceOverStack = useRef(props.audio);
    const [isPlayed, setIsPlayed] = useState(false);
    const [playerState, setPlayerState] = useState('paused');
    const [noiseLoaded, setNoiseLoaded] = useState(false);
    const [voiceOverLoaded, setVoiceOverLoaded] = useState(false);
    const [noiseDb, setNoiseDb] = useState(0);
    const [voiceOverDb, setVoiceOverDb] = useState(0);
    const [maxDb, setMaxDb] = useState();
    const [minDb, setMinDb] = useState();
    const soundType = useRef();
    const dbFix = useRef();
    const [noisePlayer, setNoisePlayer] = useState(null);
    const [voiceOverPlayer, setVoiceOverPlayer] = useState(null);

    const [noiseVolTime, setSpVolTime] = useState([]);

    const handleSkip = async () => {
        await handleNext(true);
    };
    const handleNext = async (isNull = false) => {
        if (props.content.last) {
            noisePlayer.unload();
            setNoisePlayer(null);
        } else {
            noisePlayer.stop();
        }
        voiceOverPlayer.unload();
        setVoiceOverPlayer(null);
        setVoiceOverLoaded(false);
        const answers = {};
        let labelL;
        let labelS;
        let labelSNR;
        let labelTiming;
        switch (soundType.current) {
            case 'whisper':
                labelL = 'LnosieWhisp';
                labelS = 'Nwhisp';
                labelSNR = 'SNRwhisp';
                labelTiming = 'Nwhisp(t)';
                break;

            case 'normal':
                labelL = 'LnosieSp';
                labelS = 'Nsp';
                labelSNR = 'SNRsp';
                labelTiming = 'Nsp(t)';
                break;

            case 'shouting':
                labelL = 'LnosieShout';
                labelS = 'Nshout';
                labelSNR = 'SNRshout';
                labelTiming = 'Nshout(t)';
                break;

            default:
                break;
        }
        answers[labelL] = isNull ? null : dbToLinear(noiseDb);
        answers[labelS] = isNull ? null : numberToFixed(noiseDb);
        answers[labelSNR] = isNull
            ? null
            : numberToFixed(voiceOverDb - noiseDb);
        answers[labelTiming] = noiseVolTime;
        await props.insertMultiAnswers(answers);
        setIsPlayed(false);
        setSpVolTime([]);
        props.actionNext();
    };

    useInterval(() => {
        if (noisePlayer && noisePlayer.playing()) {
            setSpVolTime((current) => [
                ...current,
                { 'T(t)': noisePlayer.seek(), 'S(t)': noiseDb },
            ]);
        }
    }, 200);

    useEffect(() => {
        if (noisePlayer && playerState === 'playing') {
            noisePlayer.play();
        } else {
            if (noisePlayer) {
                noisePlayer.pause();
            }
        }
    }, [playerState, noisePlayer]);

    useEffect(() => {
        if (voiceOverPlayer) {
            const voVol =
                soundType.current === 'whisper'
                    ? voiceOverDb + dbFix.current
                    : voiceOverDb - dbFix.current;
            voiceOverPlayer.volume(dbToLinear(voVol));

            if (!debug) {
                return;
            }

            console.log(
                `dB: ${voiceOverDb}, Real dB: ${voVol}, Linear: ${dbToLinear(
                    voVol
                )}`
            );
        }
    }, [voiceOverDb, voiceOverPlayer, debug]);

    useEffect(() => {
        if (noisePlayer) {
            noisePlayer.volume(dbToLinear(noiseDb));
            if (!debug) {
                return;
            }
            console.log(`dB: ${noiseDb}, Linear: ${dbToLinear(noiseDb)}`);
        }
    }, [noiseDb, noisePlayer, debug]);

    useEffect(() => {
        scrollTop();
        soundType.current = props.content.soundType;
        dbFix.current = props.content.soundFix;
        if (soundType.current === 'whisper') {
            setNoiseDb(props.initialDb.Swhisp - 5);
            setVoiceOverDb(props.initialDb.Swhisp);
            const maxDbVal = setMaxDbVolume(props.initialDb.Swhisp, 20);
            const minDbVal = setMinDbVolume(props.initialDb.Swhisp, 50);
            setMaxDb(maxDbVal);
            setMinDb(minDbVal);
        }
        if (soundType.current === 'normal') {
            setNoiseDb(props.initialDb.Sm - 5);
            setVoiceOverDb(props.initialDb.Sm);
            const maxDbVal = setMaxDbVolume(props.initialDb.Sm, 20);
            const minDbVal = setMinDbVolume(props.initialDb.Sm, 50);
            setMaxDb(maxDbVal);
            setMinDb(minDbVal);
        }
        if (soundType.current === 'shouting') {
            setNoiseDb(props.initialDb.Sshout - 5);
            setVoiceOverDb(props.initialDb.Sshout);
            const maxDbVal = setMaxDbVolume(props.initialDb.Sshout, 20);
            const minDbVal = setMinDbVolume(props.initialDb.Sshout, 50);
            setMaxDb(maxDbVal);
            setMinDb(minDbVal);
        }
        setVoiceOverPlayer(
            new Howl({
                src: voiceOverStack.current[soundType.current].location,
                // html5: true,
                onload: () => {
                    setVoiceOverLoaded(true);
                },
                onplay: () => {
                    setPlayerState('playing');
                    setIsPlayed(true);
                },
                onpause: () => {
                    setPlayerState('paused');
                },
                onstop: () => {
                    setPlayerState('paused');
                },
                onend: () => {
                    setPlayerState('paused');
                },
            })
        );
    }, [props.content.soundType, props.initialDb, props.content.soundFix]);

    useEffect(() => {
        let loaded = true;
        if (loaded) {
            setNoisePlayer(
                new Howl({
                    src: noiseStack.current[0][1][0].location,
                    // html5: true,
                    loop: true,
                    onload: () => {
                        setNoiseLoaded(true);
                    },
                })
            );
        }
        return function cleanup() {
            loaded = false;
            setNoisePlayer(null);
        };
    }, []);
    return (
        <React.Fragment>
            <main className="main screen">
                <h1>{props.content.soundTitle}</h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html: props.content.soundDesc,
                    }}
                    style={{ textAlign: 'center', marginBottom: '10px' }}
                ></p>
                <AudioDescription speaker="דובר" />
                <PlayerControllers
                    play={() => {
                        voiceOverPlayer.play();
                    }}
                    pause={() => {
                        voiceOverPlayer.pause();
                    }}
                    playerState={playerState}
                    audioLoaded={noiseLoaded && voiceOverLoaded ? true : false}
                />
                <Slider
                    min={minDb}
                    max={maxDb}
                    step={step}
                    value={noiseDb}
                    playerState={playerState}
                    sliderAction={(value) => {
                        setNoiseDb(Number(value));
                    }}
                    increaseAction={() => {
                        setNoiseDb(
                            noiseDb + step > maxDb ? maxDb : noiseDb + step
                        );
                    }}
                    decreaseAction={() => {
                        setNoiseDb(
                            noiseDb - step < minDb ? minDb : noiseDb - step
                        );
                    }}
                />
                <DoNotChangeNotice />
            </main>
            <FooterActions
                actionNext={handleNext}
                actionSkip={handleSkip}
                canSkip="true"
                skipDisabled={isPlayed ? false : true}
                nextDisabled={isPlayed ? false : true}
            />
        </React.Fragment>
    );
}
