import React, { Fragment, useState, useEffect, useCallback } from 'react';
import BreakDoodle from '../../components/BreakDoodle';
import FooterActions from '../../components/FooterActions';
import './Break.css';

export default function Break(props) {
    const [takeBreak, setBreak] = useState(false);
    let totalSeconds = 0;
    let currentSection = props.currentSection;
    const handleNext = () => {
        props.actionNext();
        props.incrementSection();
    };
    const setTime = useCallback(() => {
        ++totalSeconds;
        var h = Math.floor(totalSeconds / 3600);
        var m = Math.floor((totalSeconds % 3600) / 60);
        var s = Math.floor((totalSeconds % 3600) % 60);

        document.querySelector('.sec').textContent = pad(s);
        document.querySelector('.min').textContent = pad(m);
        document.querySelector('.hou').textContent = pad(h);
    }, [totalSeconds]);

    const handleTakeBreak = () => {
        setBreak(true);
    };

    useEffect(() => {
        let timer;
        if (takeBreak) {
            timer = setInterval(setTime, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [takeBreak, setTime]);

    return (
        <Fragment>
            <main className="main screen">
                {!takeBreak && (
                    <>
                        <h1 className="screen-title">
                            סיימת את שלב {currentSection} בהצלחה
                        </h1>
                        <p style={{ textAlign: 'center' }}>
                            אם ברצונך לקחת הפסקה קלה, אנו מציעים לעשות זאת כעת
                        </p>
                    </>
                )}

                <BreakDoodle />
                {takeBreak && (
                    <div className="counter">
                        <div>
                            <span className="time sec">00</span>
                            <span className="text">שניות</span>
                        </div>
                        <div>
                            <span className="time min">00</span>
                            <span className="text">דקות</span>
                        </div>

                        <div>
                            <span className="time hou">00</span>
                            <span className="text">שעות</span>
                        </div>
                    </div>
                )}
            </main>

            <FooterActions
                actionNext={handleNext}
                //showBreakButton={takeBreak ? false : true}
                //actionBreak={handleTakeBreak}
            />
        </Fragment>
    );
}
const pad = (val) => {
    var valString = val + '';
    if (valString.length < 2) {
        return '0' + valString;
    } else {
        return valString;
    }
};
