const arrayShuffle = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

const objShuffle = (obj) => {
    const reorderedObj = {};

    const arr = arrayShuffle(Object.entries(obj));

    arr.forEach((el) => {
        reorderedObj[el[0]] = {};
        let x = Object.entries(el[1]);
        x.forEach((v) => {
            reorderedObj[el[0]][v[0]] = v[1];
        });
    });
    // console.log(reorderedObj);
    return reorderedObj;
};

const fitHeight = () => {
    const fixedHeader = document.querySelector('.fixed-top');
    const appWrapper = document.querySelector('.App');
    appWrapper.style.paddingTop = fixedHeader.clientHeight + 'px';
    return;
};

const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

const dbToLinear = (dbVal) => {
    if (dbVal <= -140) {
        return 0;
    }
    if (dbVal > 1) {
        return 1;
    }
    return Math.pow(10, dbVal / 20);
};

const linearToDb = (linearVal) => {
    if (linearVal === 0) {
        return -140.0;
    }
    return 20.0 * Math.log10(linearVal);
};

const findAvarage = (array) => {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number' && array[i] !== null) {
            sum += array[i];
            count++;
        }
    }
    return sum / count;
};

const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const setCookies = async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/cookies`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'X-API-Key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    const output = await response.json();
    return output;
};

const getCookies = async (values) => {
    let queryString = null;
    if (values) {
        queryString = Object.keys(values)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(values[key])
                );
            })
            .join('&');
    }
    const reqUrl = queryString
        ? `${process.env.REACT_APP_API_URI}/cookies?${queryString}`
        : `${process.env.REACT_APP_API_URI}/cookies`;
    const response = await fetch(reqUrl, {
        method: 'GET',
        headers: {
            'X-API-Key': process.env.REACT_APP_API_KEY,
        },
    });

    const output = await response.json();
    return output;
};

const setParticipant = async (uuid, email = null) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URI}/participants`,
        {
            method: 'POST',
            headers: {
                'X-API-Key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uuid, email }),
        }
    );

    const output = await response.json();
    return output;
};

const setParticipantMeta = async (uuid, meta_key, meta_value) => {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/metadata`, {
        method: 'POST',
        headers: {
            'X-API-Key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuid, meta_key, meta_value }),
    });

    const output = await response.json();
    return output;
};

const getParticipantMetadata = async (uuid) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URI}/results?uuid=${uuid}`,
        {
            method: 'GET',
            headers: {
                'X-API-Key': process.env.REACT_APP_API_KEY,
            },
        }
    );

    const output = await response.json();
    return output;
};

const sendEmail = async (uuid) => {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/sendmail`, {
        method: 'POST',
        headers: {
            'X-API-Key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuid }),
    });

    const output = await response.json();
    return output;
};

const filterSounds = async (haystack, needle) => {
    const response = haystack.filter((list) => {
        return list.collection === needle;
    });
    return response[0].files;
};

const filterNoiseSounds = async (haystack, needle) => {
    const list = await filterSounds(haystack, needle);
    const response = {};
    list.forEach((sound) => {
        if (!response[sound.tag]) {
            response[sound.tag] = [];
        }
        response[sound.tag].push(sound);
    });
    return response;
};

const groupSoundEffects = async (haystack) => {
    const list = await filterSounds(haystack, 'soundEffects');
    const response = {};
    list.forEach((sound) => {
        if (!response[sound.group]) {
            response[sound.group] = {};
        }
        response[sound.group][sound.tag] = sound;
    });
    return response;
};

const groupSingers = async (haystack) => {
    const list = await filterSounds(haystack, 'singers');
    const response = {};
    list.forEach((sound) => {
        if (!response[sound.tag]) {
            response[sound.tag] = {};
        }
        if (!response[sound.tag][sound.part]) {
            response[sound.tag][sound.part] = {};
        }
        response[sound.tag][sound.part] = sound;
    });

    return response;
};
const groupVoices = async (haystack) => {
    const list = await filterSounds(haystack, 'voices');
    const response = {};
    list.forEach((sound) => {
        if (!response[sound.tag]) {
            response[sound.tag] = {};
        }

        response[sound.tag] = sound;
    });

    return response;
};
const fetchSounds = async () => {
    const res = await fetch('./db/audio.json');
    const resData = await res.json();
    const male = await filterSounds(resData, 'maleVoiceover');
    const female = await filterSounds(resData, 'femaleVoiceover');
    const voices = await groupVoices(resData);
    const noiseForVoices = await filterNoiseSounds(resData, 'noiseForVoices');
    const noises = await filterNoiseSounds(resData, 'noises');
    const effects = await groupSoundEffects(resData);
    const singers = await groupSingers(resData);

    const audio = {
        male: arrayShuffle(male),
        female: arrayShuffle(female),
        noises,
        singers,
        voices,
        noiseForVoices: arrayShuffle(Object.entries(noiseForVoices)),
        effects: arrayShuffle(Object.entries(effects)),
    };

    return audio;
};
// const fetchAudio = async () => {
//     const res = await fetch('./db/audio_.json');
//     const resData = await res.json();
//     const male = resData.filter((audio) => {
//         return audio.gender === 'male';
//     });
//     const female = resData.filter((audio) => {
//         return audio.gender === 'female';
//     });

//     const noises = {
//         ambulance: [],
//         storm: [],
//         street: [],
//         plane: [],
//         babble: [],
//         police: [],
//     };
//     const singers = {};
//     const voices = {};
//     const effects = {};

//     await resData.forEach((audio) => {
//         if (audio.type === 'noise') {
//             noises[audio.tag].push(audio);
//         } else if (audio.type === 'singer') {
//             if (!singers[audio.tag]) {
//                 singers[audio.tag] = {};
//             }
//             if (!singers[audio.tag][audio.part]) {
//                 singers[audio.tag][audio.part] = {};
//             }
//             singers[audio.tag][audio.part] = audio;
//         } else if (audio.type === 'voice') {
//             voices[audio.tag] = audio;
//         } else if (audio.type === 'soundEffect') {
//             if (!effects[audio.group]) {
//                 effects[audio.group] = {};
//             }
//             effects[audio.group][audio.tag] = audio;
//         }
//     });
//     const audio = {
//         male: arrayShuffle(male),
//         female: arrayShuffle(female),
//         noises,
//         singers,
//         voices,
//         effects: arrayShuffle(Object.entries(effects)),
//     };

//     return audio;
// };

const setMaxDbVolume = (db, increase = 20) => {
    return Number(db) + Number(increase) > 0
        ? 0
        : Number(db) + Number(increase);
};

const setMinDbVolume = (db, decrease = 50) => {
    return Number(db) - Number(decrease) < -140
        ? -140
        : Number(db) - Number(decrease);
};

const numberToFixed = (n) => {
    return Number(Number(n).toFixed(2));
};

export {
    arrayShuffle,
    fitHeight,
    uuid,
    dbToLinear,
    linearToDb,
    findAvarage,
    objShuffle,
    scrollTop,
    setCookies,
    getCookies,
    setParticipant,
    setParticipantMeta,
    sendEmail,
    getParticipantMetadata,
    // fetchAudio,
    fetchSounds,
    setMaxDbVolume,
    setMinDbVolume,
    numberToFixed,
};
