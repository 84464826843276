import React, { useState } from 'react';
import A11yToggler from '../A11yToggler';
import DecText from '../icons/decText';
import IncText from '../icons/IncText';
import Moon from '../icons/Moon';
import Sun from '../icons/Sun';
import './style.css';
export default function AccessibilityPanel(props) {
    const [expanded, setExpanded] = useState(false);
    const [fontSizeMultiplier, setFontSizeMultiplier] = useState(1);
    const originalFontSize = React.useRef();
    React.useEffect(() => {
        let loaded = true;
        if (loaded) {
            var style = window
                .getComputedStyle(document.body, null)
                .getPropertyValue('font-size');

            originalFontSize.current = parseFloat(style);
        }
        return function cleanup() {
            loaded = false;
        };
    }, []);
    return (
        <div id="a11y-panel" className="a11y-wrapper" aria-expanded={expanded}>
            <button
                id="a11y-toggler"
                aria-controls="a11y-panel"
                onClick={() => {
                    expanded ? setExpanded(false) : setExpanded(true);
                }}
                aria-label={expanded ? 'הסתר תפריט נגישות' : 'הצג תפריט נגישות'}
            >
                <A11yToggler />
            </button>
            <div className="a11y-inner">
                <button
                    className="a11y-action"
                    data-mode="light"
                    onClick={() => {
                        document
                            .querySelector('body')
                            .setAttribute('data-mode', 'light');
                        props.setMode('light');
                    }}
                >
                    <span className="icon">
                        <Sun />
                    </span>
                    <span className="text">מצב בהיר</span>
                </button>
                <button
                    className="a11y-action"
                    data-mode="dark"
                    onClick={() => {
                        document
                            .querySelector('body')
                            .setAttribute('data-mode', 'dark');
                        props.setMode('dark');
                    }}
                >
                    <span className="icon">
                        <Moon />
                    </span>
                    <span className="text">מצב כהה</span>
                </button>
                <hr />
                <button
                    className="a11y-action"
                    onClick={() => {
                        const m =
                            fontSizeMultiplier + 0.5 > 2
                                ? 2
                                : fontSizeMultiplier + 0.5;
                        setFontSizeMultiplier(m);
                        setFontSize(originalFontSize.current, m);
                    }}
                >
                    <span className="icon">
                        <IncText />
                    </span>
                    <span className="text">הגדל טקסט</span>
                </button>
                <button
                    className="a11y-action"
                    onClick={() => {
                        const m =
                            fontSizeMultiplier - 0.5 < 1
                                ? 1
                                : fontSizeMultiplier - 0.5;
                        setFontSizeMultiplier(m);
                        setFontSize(originalFontSize.current, m);
                    }}
                >
                    <span className="icon">
                        <DecText />
                    </span>
                    <span className="text">הקטן טקסט</span>
                </button>
            </div>
        </div>
    );
}

const setFontSize = (originalFontSize, fontMultiplier) => {
    // document.getElementsByTagName('body')[0].style.fontSize =
    //     originalFontSize * fontMultiplier + 'px';
    document.documentElement.style.setProperty(
        '--bettear-body-font-size',
        originalFontSize * fontMultiplier + 'px'
    );
};
