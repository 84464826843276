import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Howl } from 'howler';
import { dbToLinear, findAvarage, scrollTop } from '../../helpers';
import { useInterval } from '../../hooks/useInterval';

import GenderDoodle from '../../components/GenderDoodle';
import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
// import SkipNotice from '../../components/SkipNotice';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';
import FooterActions from '../../components/FooterActions';
import AudioDescription from '../../components/AudioDescription';

export default function AudioSNR(props) {
    const maxDbValue = useRef();
    const minDbValue = useRef();
    const step = 1;
    const gender = useRef();
    const speaker = gender.current === 'male' ? 'דובר' : 'דוברת';
    const [voiceOverPlayer, setVoiceOverPlayer] = useState(null);
    const [noisePlayer, setNoisePlayer] = useState(null);
    const [playerState, setPlayerState] = useState('paused');
    const [voiceOverFile, setVoiceOverFile] = useState(null);
    const [noiseFile, setNoiseFile] = useState(null);
    const [voiceOverLoaded, setVoiceOverLoaded] = useState(false);
    const [noiseLoaded, setNoiseLoaded] = useState(false);
    const [db, setDbValue] = useState(0);
    const initialDB = useRef();
    const voiceOverVol = useRef(null);
    const [
        shouldIncreaseVoiceOverIndex,
        setShouldIncreaseVoiceOverIndex,
    ] = useState(false);
    // const [shouldIncreaseNoiseIndex, setShouldIncreaseNoiseIndex] = useState(
    //     false
    // );
    const [canGoNext, setCanGoNext] = useState(false);
    const voiceOverIndex = useRef();
    const noiseIndex = useRef({
        ambulance: 0,
        storm: 0,
        street: 0,
    });
    let timeout = useRef();
    let noiseRef = useRef();
    let noiseFileRef = useRef(null);
    let voiceOverFileRef = useRef();
    const audioList = props.audio;
    const initialProps = props;
    const [maleAvgSNR, setMaleAvgSNR] = useState([]);
    const [femaleAvgSNR, setFemaleAvgSNR] = useState([]);
    const [pbrTiming, setPbrTiming] = useState([]);

    const handleSkip = () => {
        handleNext(true);
    };

    const handleNext = async (skipped = false) => {
        clearTimeout(timeout.current);
        if (noisePlayer) {
            noisePlayer.unload();
        }
        if (voiceOverPlayer) {
            voiceOverPlayer.unload();
        }
        setCanGoNext(false);
        setVoiceOverLoaded(false);

        if (shouldIncreaseVoiceOverIndex) {
            if (gender.current === 'male') {
                await initialProps.increaseGenderIndex('male');
            } else {
                await initialProps.increaseGenderIndex('female');
            }
        }

        let Llabel =
            gender.current === 'male'
                ? `Lm(${noiseRef.current})`
                : `Lf(${noiseRef.current})`;
        const Nlabel =
            gender.current === 'male'
                ? `Nm(${noiseRef.current})`
                : `Nf(${noiseRef.current})`;
        const SNRlabel =
            gender.current === 'male'
                ? `SNRm(${noiseRef.current})`
                : `SNRf(${noiseRef.current})`;
        let SNR = Number(initialDB.current) - Number(db);
        SNR = skipped ? null : SNR;
        const Nmtlabel =
            gender.current === 'male'
                ? `Nmt(${noiseRef.current})`
                : `Nft(${noiseRef.current})`;
        await props.insertMultiAnswers({
            [Llabel]: skipped ? null : dbToLinear(Number(db)),
            [Nlabel]: skipped ? null : skipped ? null : Number(db),
            [SNRlabel]: skipped ? null : skipped ? null : SNR,
            [Nmtlabel]: pbrTiming,
        });

        setPbrTiming([]);
        if (gender.current === 'male') {
            if (SNR === null) {
                setMaleAvgSNR([...maleAvgSNR, null]);
            } else {
                setMaleAvgSNR([...maleAvgSNR, Number(SNR)]);
            }
        } else if (gender.current === 'female') {
            if (SNR === null) {
                setFemaleAvgSNR([...femaleAvgSNR, null]);
            } else {
                setFemaleAvgSNR([...femaleAvgSNR, Number(SNR)]);
            }
        }

        if (props.content.last) {
            const finalAvgMale = maleAvgSNR;
            const finalAvFemale = femaleAvgSNR;
            if (gender === 'male') {
                if (SNR === null) {
                    finalAvgMale.push(null);
                } else {
                    finalAvgMale.push(Number(SNR));
                }
            } else {
                if (SNR === null) {
                    finalAvFemale.push(null);
                } else {
                    finalAvFemale.push(Number(SNR));
                }
            }
            await props.insertMultiAnswers({
                avgSNRm: Number(Number(findAvarage(finalAvgMale)).toFixed(2)),
                avgSNRf: Number(Number(findAvarage(finalAvFemale)).toFixed(2)),
            });
        }
        setVoiceOverPlayer(null);
        initialProps.actionNext();
    };

    const increaseVoiceOverIndex = useCallback(() => {
        if (gender.current === 'male') {
            initialProps.increaseGenderIndex('male');
        } else {
            initialProps.increaseGenderIndex('female');
        }
        voiceOverIndex.current++;
        setVoiceOverFile(
            audioList[gender.current][voiceOverIndex.current].location
        );
        setShouldIncreaseVoiceOverIndex(false);
        setVoiceOverLoaded(false);
    }, [initialProps, audioList]);

    // const increaseNoiseIndex = useCallback(() => {
    //     const noise = noiseRef.current;
    //     const index = noiseIndex.current;
    //     index[noise] =
    //         audioList.noises[noise].length <= index[noise] + 1
    //             ? 0
    //             : index[noise] + 1;
    //     noiseIndex.current = index;
    //     setNoiseFile(audioList.noises[noise][index[noise]].location);
    //     setShouldIncreaseNoiseIndex(false);
    //     setNoiseLoaded(false);
    // }, [audioList.noises]);

    useInterval(() => {
        if (noisePlayer.playing() && noiseFileRef.current !== null) {
            const tmp = pbrTiming;
            const index = tmp.findIndex((p) => p.file === noiseFileRef.current);
            const nDbKey = gender.current === 'male' ? 'Nm(t)' : 'Nf(t)';
            if (index === -1) {
                tmp.push({ file: noiseFileRef.current, timing: [] });
                tmp[tmp.length - 1].timing = [
                    { 'T(t)': noisePlayer.seek(), [nDbKey]: Number(db) },
                ];
            } else {
                tmp[index].timing = [
                    ...tmp[index].timing,
                    { 'T(t)': noisePlayer.seek(), [nDbKey]: Number(db) },
                ];
            }
            setPbrTiming(tmp);
        }
    }, 200);

    useEffect(() => {
        if (gender.current !== props.content.gender) {
            scrollTop();
            gender.current = props.content.gender;
            voiceOverIndex.current =
                props.content.gender === 'male'
                    ? props.maleIndex
                    : props.femaleIndex;
            if (
                voiceOverFile !==
                audioList[props.content.gender][voiceOverIndex.current].location
            ) {
                setVoiceOverFile(
                    audioList[props.content.gender][voiceOverIndex.current]
                        .location
                );
                setVoiceOverLoaded(false);
                voiceOverFileRef.current =
                    audioList[props.content.gender][
                        voiceOverIndex.current
                    ].location;
            }
            initialDB.current =
                props.content.gender === 'male' ? props.sm : props.sf;
            voiceOverVol.current = dbToLinear(initialDB.current);
            minDbValue.current =
                initialDB.current - 50 < -140 ? -140 : initialDB.current - 50;
            maxDbValue.current =
                initialDB.current + 20 > 0 ? 0 : initialDB.current + 20;

            noiseRef.current = props.content.audio;
            const index = noiseIndex.current[noiseRef.current];
            if (
                noiseFile !== audioList.noises[noiseRef.current][index].location
            ) {
                setNoiseFile(
                    audioList.noises[noiseRef.current][index].location
                );
                setNoiseLoaded(false);
                noiseFileRef.current =
                    audioList.noises[noiseRef.current][index].location;
            }
            setDbValue(initialDB.current - 6);
        }
        return function cleanup() {
            setVoiceOverFile(null);
            setNoiseFile(null);
        };
    }, [
        props.content.gender,
        props.content.audio,
        noiseFile,
        audioList,
        props.maleIndex,
        props.femaleIndex,
        props.sm,
        props.sf,
        voiceOverFile,
    ]);

    useEffect(() => {
        if (noisePlayer && noisePlayer.state && noisePlayer.state('loaded')) {
            noisePlayer.volume(dbToLinear(db));
            console.log(
                `Current - [dB: ${Number(db)} | Linear: ${dbToLinear(
                    db
                )}], SNR: ${initialDB.current - db}, Min - [bD: ${
                    minDbValue.current
                } | Linear: ${dbToLinear(minDbValue.current)}], Max -  [dB: ${
                    maxDbValue.current
                } | Linear: ${dbToLinear(maxDbValue.current)}]`
            );
        }
    }, [noisePlayer, db]);

    useEffect(() => {
        if (voiceOverFile !== null && voiceOverVol.current !== null) {
            setVoiceOverPlayer(
                new Howl({
                    src: voiceOverFile,
                    volume: voiceOverVol.current,
                    onload: () => {
                        console.log(
                            `Voice Over Player Loaded. Current File: ${voiceOverFile}. Volume: ${voiceOverVol.current}`
                        );
                        setVoiceOverLoaded(true);
                    },
                    onplay: () => {
                        setShouldIncreaseVoiceOverIndex(true);
                        setCanGoNext(true);
                    },

                    onend: () => {
                        increaseVoiceOverIndex();
                    },
                })
            );
        }
    }, [voiceOverFile, increaseVoiceOverIndex]);

    useEffect(() => {
        if (noiseFile !== null) {
            setNoisePlayer(
                new Howl({
                    src: noiseFile,
                    onload: () => {
                        console.log(
                            `Noise Player Loaded. Current File: ${noiseFile}`
                        );
                        setNoiseLoaded(true);
                    },
                    onplay: () => {
                        setPlayerState('playing');
                        // setShouldIncreaseNoiseIndex(true);
                    },
                    onpause: () => {
                        setPlayerState('paused');
                    },
                    onstop: () => {
                        setPlayerState('paused');
                    },
                    onend: () => {
                        setPlayerState('paused');
                        // increaseNoiseIndex();
                    },
                })
            );
        }
    }, [noiseFile]);
    // }, [noiseFile, increaseNoiseIndex]);

    useEffect(() => {
        if (playerState === 'playing') {
            if (voiceOverPlayer) {
                timeout.current = setTimeout(() => {
                    voiceOverPlayer.play();
                }, 3000);
            }
        } else {
            if (voiceOverPlayer) {
                voiceOverPlayer.pause();
            }
            clearTimeout(timeout.current);
        }
    }, [playerState, voiceOverPlayer]);

    // useEffect(() => {
    //     let loaded = true;
    //     if (loaded) {

    //     }
    //     return function cleanup() {
    //         loaded = false;
    //     };
    // }, []);

    return (
        <React.Fragment>
            <main className="main screen">
                <GenderDoodle gender={gender.current} />
                <h1 className="screen-title">דיבור על רקע רעש</h1>
                <p style={{ textAlign: 'center' }}>
                    בעת הישמע קטע הקול, יש להזיז את הנקודה הכחולה ימינה ושמאלה
                    בהתאם,{' '}
                    <strong>
                        כך שניתן יהיה להבחין ברעש הרקע, אך מבלי לפגוע ביכולת
                        להבין את הדיבור
                    </strong>
                    .
                </p>
                <AudioDescription speaker={speaker} />
                <PlayerControllers
                    playerState={playerState}
                    audioLoaded={voiceOverLoaded && noiseLoaded ? true : false}
                    play={() => {
                        if (noisePlayer) {
                            noisePlayer.play();
                        }
                    }}
                    pause={() => {
                        if (noisePlayer) {
                            noisePlayer.pause();
                        }
                    }}
                />
                <Slider
                    min={minDbValue.current}
                    max={maxDbValue.current}
                    step={step}
                    labelIncrease="הגבר את רעש הרקע"
                    labelDecrease="הנמך את רעש הרקע"
                    playerState={playerState}
                    value={db}
                    sliderAction={(value) => {
                        setDbValue(value);
                    }}
                    increaseAction={() => {
                        setDbValue(
                            db >= maxDbValue.current
                                ? maxDbValue.current
                                : Number(db) + step
                        );
                    }}
                    decreaseAction={() => {
                        setDbValue(
                            db <= minDbValue.current
                                ? minDbValue.current
                                : Number(db) - step
                        );
                    }}
                />
                {/* <SkipNotice /> */}
                <DoNotChangeNotice />
            </main>

            <FooterActions
                actionNext={handleNext}
                canSkip="true"
                skipDisabled={canGoNext === true ? false : true}
                nextDisabled={canGoNext === true ? false : true}
                actionSkip={handleSkip}
            />
        </React.Fragment>
    );
}
