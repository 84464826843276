import React, { useState, useRef, useEffect } from 'react';
import {
    dbToLinear,
    scrollTop,
    setMaxDbVolume,
    setMinDbVolume,
    numberToFixed,
} from '../../helpers';
import { useInterval } from '../../hooks/useInterval';
import { Howl } from 'howler';
import FooterActions from '../../components/FooterActions';
import PlayerControllers from '../../components/PlayerControllers';
import Slider from '../../components/Slider';
import DoNotChangeNotice from '../../components/DoNotChangeNotice';

export default function VolumeRange(props) {
    const debug = props.debug;
    const initialDb = props.sm;
    const audioList = props.audio;
    const step = 1;
    const maxDbVal = setMaxDbVolume(initialDb, 20);
    const minDbVal = setMinDbVolume(initialDb, 50);
    const dbFix = useRef(0);
    const soundType = useRef();
    const [dynamicPlayer, setDynamicPlayer] = useState(null);
    const [dynamicPlayerLoaded, setDynamicPlayerLoaded] = useState(false);
    const [dynamicPlayerState, setDynamicPlayerState] = useState('paused');
    const [dynamicPlayerDb, setDynamicPlayerDb] = useState(initialDb - 4);
    const [dynamicPlayed, setDynamicPlayed] = useState(false);
    const [dynamicVolTime, setDynamicVolTime] = useState([]);

    const handleNext = async () => {
        dynamicPlayer.unload();
        setDynamicPlayer(null);
        setDynamicPlayerLoaded(false);
        const answer = {};
        const linearLabel =
            soundType.current === 'whisper' ? 'Lwhisp' : 'Lshout';
        answer[linearLabel] = dbToLinear(dynamicPlayerDb);

        const logLabel = soundType.current === 'whisper' ? 'Swhisp' : 'Sshout';
        answer[logLabel] = numberToFixed(dynamicPlayerDb);

        const timingLabel =
            soundType.current === 'whisper' ? 'Swhisp(t)' : 'Sshout(t)';
        answer[timingLabel] = dynamicVolTime;

        await props.insertMultiAnswers(answer);

        setDynamicPlayed(false);
        props.actionNext();
    };

    const stopAllSounds = () => {
        if (dynamicPlayerState === 'playing') {
            dynamicPlayer.pause();
            setDynamicPlayerState('paused');
        }
    };

    useInterval(() => {
        if (dynamicPlayer && dynamicPlayer.playing()) {
            setDynamicVolTime((current) => [
                ...current,
                { 'T(t)': dynamicPlayer.seek(), 'S(t)': dynamicPlayerDb },
            ]);
        }
    }, 200);

    useEffect(() => {
        if (dynamicPlayer) {
            const fixedVolume =
                soundType.current === 'whisper'
                    ? dynamicPlayerDb + dbFix.current
                    : dynamicPlayerDb - dbFix.current;
            dynamicPlayer.volume(dbToLinear(fixedVolume));
            
            if (!debug) {
                return;
            }

            console.log(
                `Shout Player - Max bD: ${maxDbVal}, Min dB: ${minDbVal}, Current dB: ${dynamicPlayerDb}, Real dB: ${fixedVolume}, Linear: ${dbToLinear(
                    fixedVolume
                )}`
            );
        }
    }, [dynamicPlayer, dynamicPlayerDb, minDbVal, maxDbVal, debug]);

    useEffect(() => {
        scrollTop();
        dbFix.current = props.content.soundFix;
        soundType.current = props.content.soundType;
        const dynamicPlayerInitialDb =
            props.content.soundType === 'whisper'
                ? initialDb - dbFix.current
                : initialDb + dbFix.current;
        setDynamicPlayerDb(dynamicPlayerInitialDb);

        setDynamicPlayer(
            new Howl({
                src: audioList[props.content.soundType].location,
                // html5: true,
                onload: () => {
                    setDynamicPlayerLoaded(true);
                },
                onend: () => {
                    setDynamicPlayerState('paused');
                },
                onplay: () => {
                    setDynamicPlayerState('playing');
                    setDynamicPlayed(true);
                },
                onpause: () => {
                    setDynamicPlayerState('paused');
                },
                onstop: () => {
                    setDynamicPlayerState('paused');
                },
            })
        );
    }, [props.content.soundType, props.content.soundFix, initialDb, audioList]);

    return (
        <>
            <main className="main screen">
                <h1>דינאמיקה מילולית ומנעד דיבור</h1>
                <p style={{ textAlign: 'center' }}>
                    בבדיקה זו נבחן את מנעד עוצמת הדיבור, בתלות באופן הגשת
                    הדיבור/טונציה (לחישה, צעקה...).
                </p>
                {/* <p style={{ textAlign: 'center' }}>
                    יש להזיז את המחוון, בהתאמה, כך שיתקבל אופי הדיבור הרצוי,
                    ומבלי לפגוע ביכולת ההבנה המילולית.
                </p> */}
                <div className="player-wrapper">
                    <h2>{props.content.soundTitle}</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: props.content.soundDesc,
                        }}
                        style={{ textAlign: 'center' }}
                    ></p>
                    <PlayerControllers
                        play={() => {
                            if (dynamicPlayer) {
                                dynamicPlayer.play();
                            }
                        }}
                        pause={() => {
                            if (dynamicPlayer) {
                                dynamicPlayer.pause();
                            }
                        }}
                        audioLoaded={dynamicPlayerLoaded}
                        playerState={dynamicPlayerState}
                    />
                    <Slider
                        min={minDbVal}
                        max={maxDbVal}
                        step={step}
                        value={dynamicPlayerDb}
                        playerState={dynamicPlayerState}
                        sliderAction={(value) => {
                            setDynamicPlayerDb(Number(value));
                        }}
                        increaseAction={() => {
                            setDynamicPlayerDb(
                                dynamicPlayerDb + step > maxDbVal
                                    ? maxDbVal
                                    : dynamicPlayerDb + step
                            );
                        }}
                        decreaseAction={() => {
                            setDynamicPlayerDb(
                                dynamicPlayerDb - step < minDbVal
                                    ? minDbVal
                                    : dynamicPlayerDb - step
                            );
                        }}
                    />
                </div>
                <DoNotChangeNotice />
            </main>
            <FooterActions
                actionNext={handleNext}
                nextDisabled={dynamicPlayed ? false : true}
            />
        </>
    );
}
